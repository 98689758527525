import React from 'react';


const Tour1 = () => {
  return (
      <div className="tour-details-container">

        <div className="tour-details-overview">
          <h2>Overview</h2>
          <p>
            Embark on an exhilarating 30-minute four-wheeler excursion through the picturesque city of Jarabacoa. Our guided tour takes you on a thrilling journey, passing by iconic tourist spots and breathtaking scenery that encapsulates the beauty of this vibrant city.
          </p>
        </div>

        <div className="tour-details-highlights">
          <h2>Tour Highlights</h2>
          <ul>
            <li>Scenic Routes: Traverse the city on off-road paths, experiencing the natural beauty of Jarabacoa from a unique perspective.</li>
            <li>Touristic Spots: Discover key tourist attractions as our expert guides lead you through the heart of the city.</li>
            <li>Photo Opportunities: Capture memorable moments at designated stops, including a pause at the renowned Jarabacoa sign. Take in the stunning views and snap photos to commemorate your adventure.</li>
          </ul>
        </div>

        <div className="tour-details-details">
          <h2>Details</h2>
          <p>Duration: 30 minutes of non-stop excitement and exploration.</p>
          <p>Inclusions:</p>
          <ul>
            <li>Guided tour with experienced instructors.</li>
            <li>Use of four-wheeler vehicle.</li>
            <li>Safety gear and briefing.</li>
          </ul>
          <p>Photo Stops:</p>
          <ul>
            <li>Big Jarabacoa Sign: Capture the essence of your journey with a photo at this iconic landmark.</li>
          </ul>
        </div>

        <div className="tour-details-important">
          <h2>Important Information</h2>
          <p>Departure Point: 49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, Dominican Republic</p>
          <p>Age Requirements: Participants of all ages are welcome. For kids, parental consent is required.</p>
          <p>Safety Measures: Helmets and safety gear provided. Follow the guide's instructions for a safe and enjoyable experience.</p>
        </div>
      </div>
  );
};

export default Tour1;
