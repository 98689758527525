import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_ROOM_BY_PRICE_ID } from "../utils/queries";

import DateRangePicker from "../componentsES/DateRangePicker";

import Carousel from "../componentsES/Carousel"; 

import { BsImages, BsXLg, BsClock, BsCalendar2Date, BsArrowLeft, BsSnow } from "react-icons/bs";
import { PiFan, PiPawPrintLight, PiQuestionLight  } from "react-icons/pi";
import { FaSwimmingPool, FaParking, FaWifi   } from "react-icons/fa";
import { RxExit, RxEnter, RxInfoCircled } from "react-icons/rx";
import { MdOutlineNoFood } from "react-icons/md";

import CafeColao from "../images/cafe-colao-logo.png"

const RoomDetailsPage = () => {
  const [viewAllVisible, setViewAllVisible] = useState(false);
  const { id } = useParams();

  const priceId = id;
  
  useEffect(() => {
    localStorage.removeItem('roomId');
    localStorage.removeItem('roomReservationData');

    localStorage.setItem("roomId", JSON.stringify(priceId));
  }, []);

    // Add a useEffect to apply/remove overflow: hidden to the body
    useEffect(() => {
      if (viewAllVisible) {
        // When viewAllVisible is true, apply overflow: hidden to the body
        document.body.style.overflow = "hidden";
      } else {
        // When viewAllVisible is false, remove overflow: hidden from the body
        document.body.style.overflow = "auto";
      }
  
      // Cleanup function to remove the style when the component unmounts
      return () => {
        document.body.style.overflow = "auto";
      };
    }, [viewAllVisible]);

  const { loading, error, data } = useQuery(QUERY_ROOM_BY_PRICE_ID, {
    variables: { priceId },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const room = data.roomByPriceId;

  const toggleViewAll = () => {
    setViewAllVisible(!viewAllVisible);
  };
  
  return (
    <div className="roomDetail activityDetail">
      <div className="room-content activity-content">
        <div className="backNav">
          <a href="/hotel"><BsArrowLeft/>All Rooms</a>
        </div>
        <div style={{width: "100%", textAlign: "center"}}>
          <a href="/"><img src={CafeColao} alt="Description of the image" style={{ width: "90px" }}/></a>
        </div>
        <section className="photo-array">
          <div style={{ backgroundImage: `url(${room.images[0]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[1]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[2]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[3]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[4]})`}} onClick={toggleViewAll} ><div className="view-all"><BsImages/>View All</div></div>
        </section>
        {viewAllVisible && (
          <>
            <Carousel images={room.images} />
            <button onClick={toggleViewAll} className="close-full"><BsXLg/></button>
          </>
        )}
        <div className="mobile-carousel">
          <Carousel images={room.images} />
        </div>
        <h1>{room.title}</h1>
        <div className="desc-cards">

          {/* Duration Card */}
          {room.ac === 'true' ? (
            <div className="desc-card">
              <BsSnow/>
              <div className="text">
                <h3>Aire Acondicionado</h3>
              </div>
            </div>
            ) : (
              <div className="desc-card">
              <PiFan/>
              <div className="text">
                <h3>Ventilador</h3>
              </div>
            </div>
            )}
            <div className="desc-card">
              <FaSwimmingPool/>
              <div className="text">
                <h3>Piscina</h3>
              </div>
            </div>
            <div className="desc-card">
              <FaParking/>
              <div className="text">
                <h3>Estacionamiento Gratis</h3>
              </div>
            </div>
            <div className="desc-card">
              <FaWifi/>
              <div className="text">
                <h3>Wi-Fi gratis</h3>
              </div>
            </div>
        </div>
        {/* <div className='DateRangePickerContainer'>
            <DateRangePicker roomId={priceId} />
        </div> */}
        <div className="room-detail-container">
          {/* <h2 className="room-heading">Bienvenido a nuestro retiro sereno en Jarabacoa, República Dominicana</h2> */}
          <br />

          <div className="room-section">
            <h2 className="room-subheading">Tu estancia en el Hotel Cafe Colao</h2>
            <p className="room-paragraph">
              Sumérgete en el encanto local con una experiencia única inspirada
              en la esencia cultural de Jarabacoa. Rodeado de exuberante vegetación y un
              jardín pintoresco, este alojamiento íntimo está diseñado para dos
              personas que buscan una escapada tranquila. Experimenta la autenticidad de la
              República Dominicana mientras disfrutas de las comodidades de la vida moderna.
            </p>
          </div>

          {/* Relaxation and Recreation */}
          <div className="room-section">
            <h2 className="room-subheading">Relajación y Recreación</h2>
            <p className="room-paragraph">
              Nuestro hotel ofrece acceso rápido a una refrescante piscina, un animado bar y
              otras instalaciones recreativas. Ya sea tomando el sol junto a la
              piscina o disfrutando de una bebida fresca en el bar, encontrarás que nuestro establecimiento
              es una mezcla perfecta de relajación y ocio.
            </p>
          </div>

        </div>
        <div className="house-rules">
          <h2 className="room-subheading">Normas y Detalles</h2>
          <div className="container">
            <div className="info">
              <div className="title"><RxEnter /> Check-In</div>
              <div className="detail"><p>3:00 PM - 7:00 PM</p></div>
            </div>
            <div className="info">
              <div className="title"><RxExit /> Check-Out</div>
              <div className="detail"><p>Hasta las 12:00 PM</p></div>
            </div>
            <div className="info">
              <div className="title"><RxInfoCircled /> Cancelaciones</div>
              <div className="detail"><p>Actualmente no se permiten cancelaciones para reservas realizadas en línea. ¿Preguntas? (829) 752-3723 </p></div>
            </div>
            <div className="info">
              <div className="title"><PiPawPrintLight /> Mascotas</div>
              <div className="detail"><p>No se permiten mascotas.</p></div>
            </div>
            <div className="info">
              <div className="title"><MdOutlineNoFood /> Comida</div>
              <div className="detail"><p>No se permite comida y bebidas en las habitaciones. (Solo agua)</p></div>
            </div>
            <div className="info">
              <div className="title"><PiQuestionLight /> ¿Preguntas?</div>
              <div className="detail"><p>Para cualquier pregunta o asistencia, contáctenos al <b>(829) 752-3723</b></p></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default RoomDetailsPage;