import React from "react";
import { BsClock, BsArrowRightShort } from "react-icons/bs";
import { Link } from "react-router-dom";

const EventCard = ({ title, images, price, time, priceId }) => {
  const precioDOP = price;
  const precioUSD = Math.round(precioDOP / 56);
  return (
    <div className="eventCard">
      <div className="card-container">
        <div className="card-thumbnail">
          <Link to={`/tours/${priceId}`}>
            <img src={images[0]} alt={title} />{" "}
            {/* Usa la primera imagen del array 'images' */}
          </Link>
          <p className="card-label">
            <BsClock className="card-icon" />
            <span>{time}</span>
          </p>
        </div>
        <div className="card-body">
          <h3 className="card-title">
            <Link to={`/tours/${priceId}`}>{title}</Link>
          </h3>
          <div className="card-bottom">
            <div className="book-button">
              <Link to={`/tours/${priceId}`}>
                Reserva Ahora <BsArrowRightShort className="arrow-icon" />{" "}
              </Link>
            </div>
            <div className="card-info">
              <span>Desde</span>
              <h6>
                <span className="currency">RD </span>${precioDOP}{" "}
                <span> Fourwheel</span>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
