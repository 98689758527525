import React, {useState, useEffect} from 'react';
import io from 'socket.io-client'
import { Navigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import AdminCalendar from './adminCalendar';
import AdminDateRangePicker from './adminDateRangePicker';

import { generateOrderNumber } from '../utils/orderNumber';

import { QUERY_BOOKINGS, QUERY_ACTIVITIES } from '../utils/queries';
import { QUERY_EMPLOYEE, QUERY_ME, QUERY_ROOM_RESERVATIONS, QUERY_ROOMS } from '../utils/queries';
import { ADD_BOOKING, ADD_ROOM_RESERVATION } from '../utils/mutations';

import 'bootstrap/dist/css/bootstrap.min.css';

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import ToggleButton from 'react-bootstrap/ToggleButton'
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

import { MdLightMode, MdDarkMode } from "react-icons/md";

import Auth from '../utils/auth';



const DashboardHotel = () => {
  const { username: userParam } = useParams();

  const[createBooking, { error, newBookingData }] = useMutation(ADD_ROOM_RESERVATION);

  const { loading, data } = useQuery(userParam ? QUERY_EMPLOYEE : QUERY_ME, {
    variables: { username: userParam },
  });

  const employee = data?.me || data?.employee || {};

  const { loadingBooking, data: dataBooking, refetch: refetchBookings } = useQuery(QUERY_ROOM_RESERVATIONS);
  const allBookings = dataBooking?.roomReservations || []

  const { loadingActivities, data: dataActivies } = useQuery(QUERY_ROOMS);
  const allActivities = dataActivies?.rooms || []

  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedTourDuration, setSelectedTourDuration] = useState(null);
  const [selectedTourTitle, setSelectedTourTitle] = useState(null);

  const [adminCalendarKey, setAdminCalendarKey] = useState(0);

  const [totalCost, setTotalCost] = useState(null);
  const [selectedTourPrice, setselectedTourPrice] = useState(null);
  const [isBreakfastChecked, setIsBreakfastChecked] = useState(false);

  const [dateChange, setDateChange] = useState('Initial State');
  const [totalNights, setTotalNights] = useState('');

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
  });

  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const [searchInputs, setSearchInputs] = useState({});

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const orderNumber = generateOrderNumber();
  
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalFourWheelers, setTotalFourWheelers] = useState(0);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDetail = () => setShowDetail(false);
  const handleShowDetail = () => setShowDetail(true);
  
  const [selectedBookingDetail, setSelectedBookingDetail] = useState(null);

  const toggleMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode);
  };

  const handleTourSelection = (activity, duration, activityPrice, activityTitle) => {
    setSelectedTour(activity);
    setSelectedTourDuration(duration);
    setselectedTourPrice(activityPrice)
    setSelectedTourTitle(activityTitle)
    localStorage.setItem("tourId", JSON.stringify(activity));
    setTotalCost(totalNights > 0 ? totalNights * activityPrice : 0)

    setAdminCalendarKey((prevKey) => prevKey + 1);
  };

  const calculateActivityData = (activityId) => {
    let totalRevenue = 0;
    let totalNights = 0;
  
    // Iterate through bookings
    allBookings.forEach((booking) => {
      // Check if the booking corresponds to the current activity
      if (booking.activityId === activityId) {
        // Calculate the total nights for the booking
        const checkInDate = new Date(booking.checkInDate);
        const checkOutDate = new Date(booking.checkOutDate);
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const nights = Math.round(Math.abs((checkInDate - checkOutDate) / oneDay));
  
        // Accumulate the total nights
        totalNights += nights;
  
        // Accumulate the total revenue based on nights * price
        totalRevenue += nights * Number(booking.price); // Convert to number before adding
      }
    });
    return { totalRevenue, totalNights };
  };

  useEffect(() => {
    const socket = io('https://cafecolaord.com', { transports: ['polling'] });

    socket.on('newBooking', (socketData) => {
      // Update your UI with the new booking data
      refetchBookings()
    });

    const storedMode = localStorage.getItem('darkMode');
    if (storedMode !== null) {
      setIsDarkMode(storedMode === 'true');
    }
    return () => {
      socket.disconnect();
    }
  }, []);

  useEffect(() => {
    // const reservationDates = JSON.parse(localStorage.getItem("reservationDates"));
    // const checkInNew = new Date(reservationDates.checkIn);
    // const checkOutNew = new Date(reservationDates.checkOut);
    // const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // const nights = Math.round(Math.abs((checkInNew - checkOutNew) / oneDay));
    // setTotalNights(nights)
    // console.log(nights);
    if (isBreakfastChecked) {
      setTotalCost(totalCost + 1000 * totalNights)
    } else {
      setTotalCost(totalCost - 1000 * totalNights)
    }
  }, [isBreakfastChecked]);


  // navigate to personal profile page if username is yours
  if (Auth.loggedIn() && Auth.getProfile().data.username === userParam) {
    return <Navigate to="/admin" />;
  }
  
  const loadingOrError = loading || loadingBooking || loadingActivities;

  if (loadingOrError) {
    return <h1>LOADING...</h1>;
  }

  if (!employee?.username) {
    window.location.href = '/admin/hotel/login';
  }


  const columns = [
    { Header: 'Reserva', accessor: 'orderNumber' },
    { Header: 'Cliente', accessor: 'firstName' },
    { Header: 'Llegada', accessor: 'checkInDate' },
    // { Header: 'Tiempo', accessor: 'time' },
    // { Header: 'Fourwheels', accessor: 'fourwheels' },
    { Header: 'Teléfono', accessor: 'phone' },
    { Header: 'Status', accessor: 'paymentStatus' },
    // { Header: 'Correo Electrónico', accessor: 'email' },
  ];

    // Sorting function for checkInDate in descending order
    const sortedBookings = allBookings.slice().sort((a, b) => new Date(b.checkInDate) - new Date(a.checkInDate));


  const handleSearchInputChange = (columnKey, value) => {
    setSearchInputs((prevSearchInputs) => ({
      ...prevSearchInputs,
      [columnKey]: value,
    }));
  };

  const filteredBookings = sortedBookings.filter((booking) =>
  columns.every((column) => {
    const { accessor } = column;
    const searchValue = searchInputs[accessor] ? searchInputs[accessor].toLowerCase() : '';
    const cellValue = booking[accessor].toString().toLowerCase();

    if (accessor === 'firstName' || accessor === 'lastName') {
      // Check if the search value is included in either first or last name
      return booking['firstName'].toLowerCase().includes(searchValue) ||
             booking['lastName'].toLowerCase().includes(searchValue);
    } else {
      // For other columns, perform the default search logic
      return cellValue.includes(searchValue);
    }
  })
);

const handleDateChange = (dataFromChild) => {
  setDateChange(dataFromChild);
  const reservationDates = JSON.parse(localStorage.getItem("reservationDates"));
  const checkInNew = new Date(reservationDates.checkIn);
  const checkOutNew = new Date(reservationDates.checkOut);
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const nights = Math.round(Math.abs((checkInNew - checkOutNew) / oneDay));
  setTotalNights(nights)
  setTotalCost(nights > 0 ? nights * selectedTourPrice : 0)
};

const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
  if (name == "fourwheels" && value >= 1) {
    setTotalCost(value * selectedTourPrice)
  }
};

// Step 3: Add onSubmit handler to gather and handle the form data
const handleSubmit = async (event) => {
  event.preventDefault();

  // Grab values from local storage
  const tourId = JSON.parse(localStorage.getItem('tourId'));
  const bookingDate = JSON.parse(localStorage.getItem('bookingDate'));
  const bookingTime = JSON.parse(localStorage.getItem('bookingTime'));
  const reservationDates = JSON.parse(localStorage.getItem("reservationDates"))

  // Update the form data with values from local storage
  const updatedFormData = {
    ...formData,
    roomId: tourId,
    checkInDate: reservationDates.checkIn,
    checkOutDate: reservationDates.checkOut,
    paymentStatus: "paid",
    orderNumber: orderNumber,
    price: selectedTourPrice,
    roomTitle: selectedTourTitle,
    breakfast: isBreakfastChecked ? "true" : "false" 
  };

  try {
    const { data } = await createBooking({
      variables: updatedFormData,
    });
    const socket = io('https://cafecolaord.com', { transports: ['polling'] });

    socket.emit('newBooking', data)

        // Clear the form data and reset relevant state variables
    setFormData({
      firstName: '',
      lastName: '',
      checkInDate: '',
      checkOutDate: '',
      phone: '',
      email: '',
    });
    setSelectedTour(null);
    setSelectedTourDuration(null);
    setTotalCost(null);
    setselectedTourPrice(null);

    handleClose()
    // Add your logic to handle the updated form data, e.g., submit it to the server
    localStorage.removeItem('tourId');
    localStorage.removeItem('bookingDate');
    localStorage.removeItem('bookingTime');
    
  } catch (error) {
    console.error('Error creating booking:', error.message);
  }

};



  const tourOptions = allActivities.map((activity) => (
    <button key={activity.priceId} className={`btn tour-option ${selectedTour === activity.priceId ? 'selected' : ''}`} onClick={() => handleTourSelection(activity.priceId, activity.duration, activity.price, activity.title)} style={{padding: "0px"}}>
      <Card>
        <Card.Body>
          <Card.Title>{activity.duration}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{activity.title}</Card.Subtitle>
        </Card.Body>
        <Card.Body className='footer-body'>
          <Card.Footer>
            <Badge bg="primary">DOP ${activity.price.toLocaleString()}</Badge>
            {/* <Badge bg="primary">USD ${Math.round(activity.price / 56).toLocaleString()}</Badge> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </button>
  ));

  const handleRowClick = (booking) => {
    setSelectedBookingDetail(booking)
    handleShowDetail();
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "paid":
        return "green";
      case "cancel":
        return "red";
      default:
        return "black"; // Set a default color if needed
    }
  };

  const now = 3;
  if(employee.role === 'owner') {
    return (
      <div className={`bookingData ${isDarkMode ? 'bg-dark' : 'bg-light'}`}>
        <h1 style={{color: "#0d6efd", fontWeight: "700"}}>HOTEL</h1>
        <Button href="/admin" variant="secondary" size="lg">Tours</Button>
        <div className='table-container'>
          <Button onClick={toggleMode} id='toggleModeButton' border='true' variant={isDarkMode ? 'outline-light' : 'outline-dark'}>
            {isDarkMode ? <MdLightMode/> : <MdDarkMode/>}
          </Button>


          <div className='stats'>
            <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
              <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Reservas Totales</Card.Subtitle>
                  <Card.Title>{allBookings.length}</Card.Title>
                  {/* <ProgressBar animated striped min={0} max={allBookings.length} now={now} label={`${Math.round((now / allBookings.length) * 100)}%`} /> */}
                </Card.Body>
              </Card>

              <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
                <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Noches Reservadas</Card.Subtitle>
                  <Card.Title>{calculateActivityData().totalNights}</Card.Title>
                </Card.Body>
              </Card>

              <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
                <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Ingresos Totales</Card.Subtitle>
                  <Card.Title>RD ${Math.round(calculateActivityData().totalRevenue)}</Card.Title>
              </Card.Body>
            </Card>
          </div>

          <div className='action-buttons'>
            <Button variant="primary" className='new' onClick={handleShow}>+ Nueva Reserva</Button>
          </div>

          <Table striped responsive bordered hover variant={isDarkMode ? 'dark' : 'light'} className='bookingTable'>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.Header}>
                    {column.Header}
                    <br />
                    <input
                      type="text"
                      className="search-input"
                      value={searchInputs[column.accessor] || ''}
                      onChange={(e) => handleSearchInputChange(column.accessor, e.target.value)}
                      placeholder="Buscar..."
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedBookings.map((booking, index) => (
                <tr key={index} onClick={() => handleRowClick(booking)} style={{ cursor: 'pointer' }}>
                  {columns.map((column, columnIndex) => (
                    <td key={`${index}-${columnIndex}`} style={{ color: column.accessor === 'paymentStatus' ? getStatusColor(booking.paymentStatus) : 'black' }}>
                      {column.accessor === 'paymentStatus' ? (
                        <div>
                          <span>{booking.paymentStatus}</span>
                        </div>
                      ) : (
                        column.accessor === 'firstName' ? `${booking.firstName} ${booking.lastName}` : booking[column.accessor]
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>


        <Modal show={show} onHide={handleClose} scrollable='true' size='xl' centered='true' backdrop='true'>
          <Modal.Header closeButton>
            <Modal.Title>Nueva Reserva</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          Selecciona Una Habitacion:
            <div className='tour-options'>
              {tourOptions}
            </div>
          {selectedTour ? (
            <div>
              Selecciona Cuando:

              {/* <AdminCalendar key={adminCalendarKey} id={selectedTour ? selectedTour : 'none'} activityDuration={selectedTourDuration} /> */}
              <div className='DateRangePickerContainer'>
                <AdminDateRangePicker roomId={selectedTour} dateChange={handleDateChange} />
              </div>
            </div>
          ) : (
            <br/>
          )}




            <Form onSubmit={handleSubmit}>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control placeholder='Nombre'name='firstName' value={formData.firstName} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control placeholder='Apellido' name='lastName' value={formData.lastName} onChange={handleInputChange}/>
                </Form.Group>
              </Row>


              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Numero Telefonico</Form.Label>
                  <Form.Control placeholder="Numero Telefonico" name='phone' value={formData.phone} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Correo Electronico</Form.Label>
                  <Form.Control type="email" placeholder="Correo Electronico" name='email' value={formData.email} onChange={handleInputChange}/>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                {/* <Form.Group as={Col} controlId="formGridAddress1">
                  <Form.Label>Fourwheelers Cantidad</Form.Label>
                  <Form.Control type='number' placeholder="Fourwheelers Cantidad" name='fourwheels' value={formData.fourwheels} onChange={handleInputChange}/>
                </Form.Group> */}

                <Form.Group as={Col} controlId="formGridAddress2">
                  {/* <Form.Label>Desayuno</Form.Label> */}
                  <Form.Check type='checkbox' placeholder="Personas Cantidad" name='breakfast' label="Desayuno" checked={isBreakfastChecked} onChange={() => setIsBreakfastChecked(!isBreakfastChecked)}/>
                </Form.Group>
              </Row>

              <h4>Total ${(totalCost * 1).toLocaleString("en-US")} DOP</h4>
              <Button variant="primary" type="submit">
                Reservar
              </Button>

            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showDetail} onHide={handleCloseDetail} scrollable='true' size='xl' centered='true' backdrop='true'>
          <Modal.Header closeButton>
            <Modal.Title>{selectedBookingDetail ? `Detalles de la Reserva` : 'Nueva Reserva'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBookingDetail ? (
              <div>
                {/* Display details of the selected booking here */}
                <p>Cliente: {`${selectedBookingDetail.firstName} ${selectedBookingDetail.lastName}`}</p>
                <p>Habitacion: {selectedBookingDetail.roomTitle}</p>
                <p>Llegada: {selectedBookingDetail.checkInDate}</p>
                <p>Salida: {selectedBookingDetail.checkOutDate}</p>
                <p>Desayuno: {selectedBookingDetail.breakfast ? "Incluido" : "No"}</p>
                <p>Número de teléfono: {selectedBookingDetail.phone}</p>
                <p>Correo Electronico: {selectedBookingDetail.email}</p>
                <p>Numero de Reserva: {selectedBookingDetail.orderNumber}</p>
                {/* Add more details as needed */}
              </div>
            ) : (
              <div>
                {/* Your existing content for creating a new booking */}
                Selecciona Un Tour:
                {/* ... rest of the content ... */}
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    )
  } else {
      return (
      <div className="mainContainer">
        <p>ALL USERS PAGE</p>
        {!userParam && (
          <div
            className="divider"
            style={{ border: '1px dotted #1a1a1a', display: 'none'}}
          >
          </div>
        )}
      </div>
  );
  }



};

export default DashboardHotel;
