import React from 'react';


const Tour3 = () => {
  return (
    <div>

        <div className="tour-details-container">

        <div className="tour-details-overview">
          <h2>Overview</h2>
          <p>
            Immerse yourself in the ultimate 2-hour off-road adventure, exploring the breathtaking landscapes of Jarabacoa. This extended tour takes you through rivers, showcases the stunning Salto Baiguate waterfall, traverses mountains, and provides numerous opportunities for capturing memorable moments.
          </p>
        </div>

        <div className="tour-details-highlights">
          <h2>Tour Highlights</h2>
          <ul>
            <li>Scenic Off-Road Trails: Explore diverse terrains, including rivers, mountains, and off-road trails.</li>
            <li>Rivers and Waterfall Experience: Navigate through rivers and witness the awe-inspiring Salto Baiguate waterfall.</li>
            <li>Mountain Views: Enjoy panoramic views of the surrounding mountains, offering picturesque backdrops for your adventure.</li>
            <li>Abundant Photo Opportunities: Capture the beauty of nature with extended photo stops, ensuring you have plenty of chances for stunning pictures.</li>
          </ul>
        </div>

        <div className="tour-details-details">
          <h2>Details</h2>
          <p>Duration: 2 hours of non-stop adventure and exploration.</p>
          <p>Inclusions:</p>
          <ul>
            <li>Guided tour with experienced off-road instructors.</li>
            <li>Use of off-road vehicle equipped for rivers, waterfalls, and mountain trails.</li>
            <li>Safety gear and briefing.</li>
          </ul>
        </div>

        <div className="tour-details-important">
          <h2>Important Information</h2>
          <p>Departure Point: 49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, Dominican Republic</p>
          <p>Age Requirements: Participants of all ages are welcome. For kids, parental consent is required.</p>
          <p>Safety Measures: Helmets and safety gear provided. Follow the guide's instructions for a safe and enjoyable experience.</p>
        </div>
      </div>
    </div>
  );
};

export default Tour3;
