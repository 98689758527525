import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Col, Button } from 'react-bootstrap';
import { AiOutlineCloseCircle } from 'react-icons/ai';

const CancellationPage = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const orderNumber = queryParams.get('OrderNumber');

  const handleGoBack = () => {
    // Redirect the user back to the reservations page
    const redirectTo = `${window.location.origin}`;
    window.location.href = redirectTo;
  };

  return (
    <Container
      fluid
      className="confirmation-container"
      style={{
        backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/slider%2Fcafe-colao.jpg?alt=media&token=94e9ffca-14db-446d-b1e4-1793f56a77b6")', // Replace with the path to your image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Col
        md={8}
        className="text-center p-4 rounded shadow-lg"
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.9)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <AiOutlineCloseCircle style={{ color: '#F44336', fontSize: '3rem' }} className="mb-3" />
        <h2 className="mb-4" style={{ color: '#333', fontSize: '2rem' }}>
          Reservation Canceled
        </h2>
        <div className="confirmation-details">
          <p className="mb-4" style={{ color: '#555', fontSize: '1.2rem' }}>
            Order Number: <span style={{ color: '#F44336', fontWeight: 'bold', fontSize: '1.5rem' }}>{orderNumber}</span>
          </p>
          <p style={{ color: '#555', fontSize: '1.2rem' }}>
            Your reservation has been canceled. We hope to serve you in the future.
          </p>
          <p style={{ color: '#555', fontSize: '1.2rem', marginTop: '20px' }}>
            If you have any questions or need further assistance, please contact us at <span style={{ color: '#333', fontWeight: '500' }}>809-986-0356</span>.
          </p>
          <Button variant="danger" onClick={handleGoBack} className="mt-3">
            Go Back
          </Button>
        </div>
      </Col>
    </Container>
  );
};

export default CancellationPage;
