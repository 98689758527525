import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../componentsES/Navbar';
import Carousel from '../componentsES/Carousel';
import Footer from '../componentsES/Footer';
import Rooms from '../componentsES/Rooms';


const hotelPageImages = [
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel4.jpg?alt=media&token=d2b18cca-2652-44f8-b025-d55638dc5229',
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel2.jpg?alt=media&token=32f6338c-d427-4dc7-9896-1e6fd207ed2c',
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel.jpg?alt=media&token=59894884-add9-4ac1-a9ca-3f95ccb6647f',
  // Add more image URLs here
];

const hotelPageCaptions = [
  'Deberías venir y probarlo por ti mismo',
  'Platos tradicionales con productos locales de la más alta calidad',
  'Descubre y experimenta una fina selección de sabores internacionales',
  // Agrega leyendas correspondientes a tus imágenes
];


const tagline = `Fusión Exclusiva de Comodidad y Cultura`;
const title = `Hotel <br/> Café Colao`;

const Hotel = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  
  const eventsRef = useRef(null);

  // Function to scroll to the Events component
  const scrollToEvents = () => {
    if (eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
    return (
      <div className='hotelContainer'>
        <Helmet>
          <title>Cafe Colao Hotel - Explora Hotel Jarabacoa</title>
          <meta name="description" content="Cafe Colao Hotel Jarabacoa - Disfruta de una estancia inolvidable en nuestro acogedor hotel en Jarabacoa. Con habitaciones cómodas, vistas impresionantes y servicios de calidad, ¡tu escapada perfecta comienza aquí! Hotel Cafe Colao Jarabacoa" />
          <meta name="keywords" content="Café Colao Hotel, Jarabacoa Hotel, Café Colao Jarabacoa, Hotel en Jarabacoa, Hotel Cafe Colao" />
          <meta property="og:title" content="Cafe Colao Hotel - Explora Hotel Jarabacoa" />
          <meta property="og:description" content="Cafe Colao Hotel Jarabacoa - Disfruta de una estancia inolvidable en nuestro acogedor hotel en Jarabacoa. Con habitaciones cómodas, vistas impresionantes y servicios de calidad, ¡tu escapada perfecta comienza aquí! Hotel Cafe Colao Jarabacoa" />
          <meta property="og:url" content="https://cafecolaord.com/hotel" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Cafe Colao Hotel - Explora Hotel Jarabacoa" />
          <meta name="twitter:description" content="Cafe Colao Hotel Jarabacoa - Disfruta de una estancia inolvidable en nuestro acogedor hotel en Jarabacoa. Con habitaciones cómodas, vistas impresionantes y servicios de calidad, ¡tu escapada perfecta comienza aquí! Hotel Cafe Colao Jarabacoa" />
          <meta name="twitter:url" content="https://cafecolaord.com/hotel" />
          {/* <link rel="canonical" href={canonicalUrl} /> */}
        </Helmet>
        <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
        <Carousel title={title} tagline={tagline} images={hotelPageImages} captions={hotelPageCaptions} scrollToEvents={scrollToEvents} />
        <div ref={eventsRef}>
          <Rooms/>
        </div>
        <Footer/>
      </div>
    )
};

export default Hotel;
