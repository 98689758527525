import React, { useState, useEffect } from "react";
import { BsChevronCompactRight, BsChevronCompactLeft } from "react-icons/bs";

const Carousel = ({ title, tagline, images, captions = [], scrollToEvents }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    const newIndex = (currentIndex - 1 + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const goToNextSlide = () => {
    const newIndex = (currentIndex + 1) % images.length;
    setCurrentIndex(newIndex);
  };

  // Automatically change the slide every 3 seconds
  useEffect(() => {
    const interval = setInterval(goToNextSlide, 5000);
    return () => clearInterval(interval); // Clean up the interval on unmount
  }, [currentIndex]);

  return (
    <div className="fullscreen-slider">
      <button  className="prev-button" onClick={goToPrevSlide} aria-label="Previous Slide">
        <BsChevronCompactLeft className="carousel-icon"/>
      </button>
      <div className="overlay"></div>
      <img src={images[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="slide"/>
      {captions.length > 0 && ( // Check if there are captions
        <div className="caption">
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <h2>{tagline}</h2>
          <p>{captions[currentIndex]}</p>
          <button className="reserve-button" onClick={scrollToEvents} >Explorar</button>
        </div>
      )}
      <button className="next-button" onClick={goToNextSlide} aria-label="Next Slide">
        <BsChevronCompactRight className="carousel-icon"/>
      </button>
    </div>
  );
};

export default Carousel;
