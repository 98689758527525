import React from 'react';
import Navbar from '../components/Navbar';

const Terms = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  return (
    <div className="terms-container policies-container">
      <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
      <div className="terms-content policies-content">
        <h1>Términos y Condiciones</h1>

        <p>
          <strong>1. Aceptación de los Términos</strong>
          <br />
          1.1. Al acceder y utilizar los servicios proporcionados por Café Colao, aceptas cumplir y quedar sujeto a estos términos y condiciones.
        </p>

        <p>
          <strong>2. Reservas y Reservaciones</strong>
          <br />
          2.1. Las reservas para los servicios ofrecidos por Café Colao se pueden realizar a través de nuestro sitio web.
          <br />
          2.2. Se puede requerir una tarjeta de crédito válida para asegurar y confirmar reservas.
          <br />
          2.3. Los precios y la disponibilidad están sujetos a cambios sin previo aviso.
          <br />
          2.4. Café Colao se reserva el derecho de rechazar o cancelar reservas por cualquier motivo.
        </p>

        <p>
          <strong>3. Pago</strong>
          <br />
          3.1. El pago de los servicios debe realizarse en su totalidad en el momento de la reserva.
        </p>

        <p>
          <strong>4. Política de Cancelación y Cambios</strong>
          <br />
          4.1. No se permiten cancelaciones, y no se proporcionarán reembolsos.
          <br />
          4.2. Los cambios en las reservas están sujetos a disponibilidad y pueden conllevar cargos adicionales.
          <br />
          4.3. Las solicitudes de cambios deben realizarse dentro de las 72 horas del servicio programado.
        </p>

        <p>
          <strong>5. Responsabilidades del Cliente</strong>
          <br />
          5.1. Los clientes son responsables de proporcionar información precisa y actualizada durante el proceso de reserva.
          <br />
          5.2. Se espera que los clientes sigan cualquier guía o instrucción proporcionada por Café Colao durante el servicio.
        </p>

        <p>
          <strong>6. Política de Privacidad</strong>
          <br />
          6.1. Café Colao respeta la privacidad de sus clientes. Consulta nuestra <a href='/policies' target='_blank'>Política de Privacidad</a> para obtener detalles sobre cómo recopilamos, usamos y protegemos tu información personal.
        </p>

        <p>
          <strong>7. Limitación de Responsabilidad</strong>
          <br />
          7.1. Café Colao no se hace responsable de lesiones, pérdidas, daños, demoras o inconvenientes causados durante la prestación de servicios.
        </p>

        <p>
          <strong>8. Información de Contacto</strong>
          <br />
          8.1. Para preguntas o inquietudes sobre estos términos y condiciones, contáctanos al 809-986-0356 o 809-761-0793.
        </p>

        <p>
          Al proceder con la reserva, reconoces que has leído, comprendido y aceptado estos términos y condiciones.
        </p>
      </div>
    </div>
  );
};

export default Terms;
