import React, {useRef} from 'react';
import Navbar from '../componentsES/Navbar';
import Carousel from '../componentsES/Carousel';
import Feature from '../componentsES/Feature';
import About from '../componentsES/About';
import Hours from '../componentsES/Hours';
import Footer from '../componentsES/Footer';
import cafecolao1 from '../images/slider/cafe-colao1.jpg'
import cafecolao2 from '../images/slider/cafe-colao2.jpeg'
import cafecolao3 from '../images/slider/cafe-colao3.jpeg'
import cafecolao4 from '../images/slider/cafe-colao4.jpeg'



const homePageImages = [
  cafecolao1,
  cafecolao3,
  cafecolao4,
];

const homePageCaptions = [
  'Deberías venir y probar por ti mismo',
  'Platos tradicionales con productos locales de la más alta calidad',
  'Descubre y experimenta una fina selección de sabores internacionales'
  // Add captions corresponding to your images
];

const title = "BIENVENIDOS"

const Home = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  
    // Create a ref for the Events component
    const eventsRef = useRef(null);

    // Function to scroll to the Events component
    const scrollToEvents = () => {
      if (eventsRef.current) {
        eventsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <div className='HomeContainer'>
        <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
        <Carousel title={title} images={homePageImages} captions={homePageCaptions} scrollToEvents={scrollToEvents} />
        <div ref={eventsRef}>
          <Feature/>
        </div>
        <About/>
        {/* <Hours/> */}
        <Footer/>
      </div>
    )
};

export default Home;
