import React from 'react';


const Tour2 = () => {
  return (
      <div className="tour-details-container">

        <div className="tour-details-overview">
          <h2>Overview</h2>
          <p>
            Embark on an unforgettable 1-hour off-road adventure through the stunning landscapes surrounding Jarabacoa. This thrilling tour takes you off the beaten path, providing you with breathtaking views, nature trails, mud, and water experiences. Pass through rivers and navigate through mountains, enhancing your journey with amazing scenery at every turn.
          </p>
        </div>

        <div className="tour-details-highlights">
          <h2>Tour Highlights</h2>
          <ul>
            <li>Scenic Off-Road Trails: Immerse yourself in the beauty of nature as you navigate through off-road trails.</li>
            <li>Breathtaking Views: Enjoy panoramic views of the city, mountains, and surrounding landscapes from elevated vantage points.</li>
            <li>Mud and Water Adventure: Experience the thrill of navigating through mud and water obstacles, adding an extra element of excitement to your journey.</li>
          </ul>
        </div>

        <div className="tour-details-details">
          <h2>Details</h2>
          <p>Duration: 1 hour of non-stop adventure and exploration.</p>
          <p>Inclusions:</p>
          <ul>
            <li>Guided tour with experienced off-road instructors.</li>
            <li>Use of off-road vehicle equipped for mud, water, and mountain trails.</li>
            <li>Safety gear and briefing.</li>
          </ul>
        </div>

        <div className="tour-details-important">
          <h2>Important Information</h2>
          <p>Departure Point: 49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, Dominican Republic</p>
          <p>Age Requirements: Participants of all ages are welcome. For kids, parental consent is required.</p>
          <p>Safety Measures: Helmets and safety gear provided. Follow the guide's instructions for a safe and enjoyable experience.</p>
        </div>
      </div>
  );
};

export default Tour2;