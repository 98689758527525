import React, { useRef} from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import Footer from '../components/Footer';
import Events from '../components/Events';


const Reservations = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  // Create a ref for the Events component
  const eventsRef = useRef(null);

  // Function to scroll to the Events component
  const scrollToEvents = () => {
    if (eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='ReservationsContainer'>
      <Helmet>
        <title>Cafe Colao Tours - Explore Jarabacoa Tours - English</title>
        <meta name="description" content="Explore Jarabacoa like never before with our expertly designed tours at Cafe Colao. From adrenaline-filled adventures to nature walks, we have something for everyone." />
        <meta name="keywords" content="Cafe Colao Tours, Jarabacoa Tours, Cafe Colao Jarabacoa, Tours in Jarabacoa" />
        <meta property="og:title" content="Cafe Colao Tours - Explore Jarabacoa Tours - English" />
        <meta property="og:description" content="Explore Jarabacoa like never before with our expertly designed tours at Cafe Colao. From adrenaline-filled adventures to nature walks, we have something for everyone." />
        <meta property="og:url" content="https://cafecolaord.com/tours" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Cafe Colao Tours - Explore Jarabacoa Tours - English" />
        <meta name="twitter:description" content="Explore Jarabacoa like never before with our expertly designed tours at Cafe Colao. From adrenaline-filled adventures to nature walks, we have something for everyone." />
        <meta name="twitter:url" content="https://cafecolaord.com/tours" />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
      <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
      <Hero scrollToEvents={scrollToEvents} />
      <div ref={eventsRef}>
        <Events />
      </div>
      <Footer />
    </div>
  );
};

export default Reservations;
