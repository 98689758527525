import React from "react";

const Hours = () => {
  return (
    <section className="hours-section parallax">
        <div className="parallax-content">
            <div className="hours-container">
                <div className="subtitle">Dine In</div>
                <div className="title">OPEN HOURS</div>
                <div className="hours">
                    <p>Saturday - Sunday: 9 AM - 1 PM </p>
                    <p>Saturday - Sunday: 9 AM - 1 PM </p>
                </div>
                <a className="reserve-button">View Menu</a>
            </div>
        </div>
    </section>
  );
};

export default Hours;
