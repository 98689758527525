import React from "react";

const BookingSummary = ({ title, cover, date, time, duration, price, fourwheels}) => {
  const coverImage = cover

  function formatDate(inputDate) {
    // Split the input date into components (month, day, year)
    const dateComponents = inputDate.split('/');
  
    // Create a JavaScript Date object
    const jsDate = new Date(dateComponents[2], dateComponents[0] - 1, dateComponents[1]);
  
    // Define the options for formatting the date
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
  
    // Format the date
    const formattedDate = jsDate.toLocaleDateString('en-US', options);
  
    return formattedDate;
  }

  const formattedDate = formatDate(date);


  return (
    <section className="booking-summary">
        <div className="summary-header" style={{ backgroundImage: `url(${coverImage})` }}>
          <div className="overlay">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="summary-info">
          <div className="details-title">Booking Details</div>
          <div><span>Date & Time</span> {formattedDate} at {time} </div>
          <div><span>Duration</span> {duration}</div>
          <div><span>Fourwheels</span> {fourwheels}</div>
          <div><span>Group Size</span> {fourwheels} - {fourwheels * 2}</div>
          <div className="location"><span>Location</span>Calle Hermanas Mirabal, Jarabacoa 41000<br/>Republica Dominicana</div>
          <div className="divider"></div>
          <div className="details-title">Price Details</div>
          <div><span>Price </span>RD ${price} x {fourwheels} Fourwheel</div>
          <div><span>Taxes</span> RD $0 (Included)</div>
          <div><span>Service Fee</span>RD ${Math.round((fourwheels * price) * 0.1)}</div>
          <div className="divider"></div>
          <div className="total-price"><span>Total Price</span> RD ${Math.round((fourwheels * price) * 1.1)}</div>
        </div>
    </section>
  );
};

export default BookingSummary;