import React, { useState, useEffect } from "react";

import CafeColao from "../images/cafe-colao-logo.png";

const Navbar = ({
  selectedLanguage,
  handleLanguageChange,
  toggleDropdown,
  dropdownVisible,
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isShortHeight, setIsShortHeight] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const checkScreenSize = () => {
    const newWidth = window.outerWidth;
    const newHeight = window.outerHeight;
    setIsMobile(newWidth <= 767);
    setIsShortHeight(newHeight <= 760);
  };

  useEffect(() => {
    checkScreenSize();

    const handleResize = () => {
      checkScreenSize();
    };

    const handleScroll = () => {
      setIsScrolled(window.pageYOffset > 30); // Adjust the value as needed

      // Close mobile nav when scrolling
      if (isMobileNavOpen) {
        setIsMobileNavOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isMobileNavOpen]);

  return (
    <div className="Navbar">
      {isMobile ? (
        <header className="header-mobile">
          <nav className={`nav-navbar ${isMobileNavOpen ? "navOpen" : ""}`}>
            <div className="content-container">
              <a href="/">
              <img
                src={CafeColao}
                alt="Descripción de la imagen"
                style={{ width: "150px" }}
              />
              </a>
              <div className="language-container">
                <div
                  className={`language-dropdown ${
                    dropdownVisible ? "visible" : ""
                  }`}
                >
                  <button onClick={toggleDropdown} className="dropdown-button">
                    {selectedLanguage === "en" ? "Inglés" : "Español"}
                    <i
                      className={`dropdown-icon ${
                        dropdownVisible ? "up" : "down"
                      }`}
                    />
                  </button>
                  <div className="dropdown-content">
                    <button
                      onClick={() => handleLanguageChange("en")}
                      className={selectedLanguage === "en" ? "active" : ""}
                    >
                      English
                    </button>
                    <button
                      onClick={() => handleLanguageChange("es")}
                      className={selectedLanguage === "es" ? "active" : ""}
                    >
                      Español
                    </button>
                  </div>
                </div>
                <button
                  className={`mobileNavToggle hamburger hamburger--spin ${
                    isMobileNavOpen ? "is-active" : ""
                  }`}
                  type="button"
                  aria-label="Abrir navegación móvil"
                  onClick={() => setIsMobileNavOpen(!isMobileNavOpen)}
                >
                  <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
              </div>
            </div>

            <ul
              className="mobileNav"
              style={{ display: isMobileNavOpen ? "flex" : "none" }}
            >
              <li className="navItem">
                <a href="/">Inicio</a>
              </li>
              {/* <li className='navItem'><a href="/">Menú</a></li> */}
              <li className="navItem">
                <a href="/tours">Tours</a>
              </li>
              <li className="navItem">
                <a href="/hotel">Hotel</a>
              </li>
              {/* <li className='navItem'><a href="/">Galería</a></li> */}
              {/* <li className='navItem'><a href="/">Contacto</a></li> */}
            </ul>
          </nav>
        </header>
      ) : (
        <header className="header-desktop">
          <nav className="nav-navbar">
            <div
              className="content-container"
              style={{ display: isScrolled || isShortHeight ? "none" : "flex" }}
            >
              <p className="address">
                Calle Hermanas Mirabal, <br />
                Jarabacoa, República Dominicana
              </p>
              <a href="/">
              <img
                src={CafeColao}
                alt="Cafe Colao"
                style={{ width: "130px", top: "0px", position: "relative" }}
              />
              </a>
              <div className="language-container">
                <div
                  className={`language-dropdown ${
                    dropdownVisible ? "visible" : ""
                  }`}
                >
                  <button onClick={toggleDropdown} className="dropdown-button">
                    {selectedLanguage === "en" ? "Inglés" : "Español"}
                    <i
                      className={`dropdown-icon ${
                        dropdownVisible ? "up" : "down"
                      }`}
                    />
                  </button>
                  <div className="dropdown-content">
                    <button
                      onClick={() => handleLanguageChange("en")}
                      className={selectedLanguage === "en" ? "active" : ""}
                    >
                      Inglés
                    </button>
                    <button
                      onClick={() => handleLanguageChange("es")}
                      className={selectedLanguage === "es" ? "active" : ""}
                    >
                      Español
                    </button>
                  </div>
                </div>
                <p className="phone">
                  &#9742; 809-986-0356 <br />
                  &#9742; 809-761-0793 <br />
                </p>
              </div>
            </div>

            <ul
              className={`desktopNav ${
                isScrolled || isShortHeight ? "navScroll" : ""
              }`}
            >
              <li className="navItem">
                <a href="/">Inicio</a>
              </li>
              {/* <li className='navItem'><a href="/">Menú</a></li> */}
              <li className="navItem">
                <a href="/tours">Tours</a>
              </li>
              <li className="navItem">
                <a href="/hotel">Hotel</a>
              </li>
              {/* <li className='navItem'><a href="/">Galería</a></li> */}
              {/* <li className='navItem'><a href="/">Contacto</a></li> */}
            </ul>
          </nav>
        </header>
      )}
    </div>
  );
};

export default Navbar;
