import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_ACTIVITIES } from "../utils/queries";
import EventCard from "./EventCard";

const Events = () => {
  const { loading, error, data } = useQuery(QUERY_ACTIVITIES);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const activities = data.activities;

  return (
    <section className="events-section">
      <div className="events-header">
        <h2>Reserva Tu Aventura</h2>
        <p>Reserva Actividades Adaptadas a Tus Intereses y Crea Recuerdos Duraderos</p>
      </div>
      <div className="events-container">
        {activities.map((activity) => (
          <EventCard
            key={activity.priceId} // Utiliza una clave única para cada tarjeta (por ejemplo, activity._id)
            title={activity.title}
            images={activity.images}
            price={activity.price}
            time={activity.duration}
            priceId={activity.priceId}
          />
        ))}
      </div>
    </section>
  );
};

export default Events;
