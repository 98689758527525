import React, { useEffect, useState, useRef  } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  QUERY_RESERVATIONS_TEMP_BY_ORDER_NUMBER,
  QUERY_RESERVATIONS_BY_ORDER_NUMBER,
} from '../utils/queries';
import { ADD_ROOM_RESERVATION } from '../utils/mutations';

import { Container, Col } from 'react-bootstrap';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const HotelCompletion = () => {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const orderNumber = queryParams.get('OrderNumber');

  if (!orderNumber) {
    const redirectTo = `${window.location.origin}/hotel`;

    // Perform the redirection
    window.location.href = redirectTo;
  }

  const hasHandledCreateBookingRef = useRef(false);

  const [createBooking] = useMutation(ADD_ROOM_RESERVATION);

  // Query to check if an official booking exists
  const { loading: officialBookingLoading, data: officialBookingData } = useQuery(
    QUERY_RESERVATIONS_BY_ORDER_NUMBER,
    {
      variables: { orderNumber },
    }
  );

  // Query for temporary booking
  const { loading: tempBookingLoading, data: tempBookingData } = useQuery(
    QUERY_RESERVATIONS_TEMP_BY_ORDER_NUMBER,
    {
      variables: { orderNumber },
    }
  );

  useEffect(() => {


    // Check if an official booking exists
    if (
      officialBookingData &&
      Array.isArray(officialBookingData.roomReservationsByOrderNumber) &&
      officialBookingData.roomReservationsByOrderNumber.length > 0
    ) {
      // Official booking found, setBooking to null to skip the next query
    } else {
      // Check if temporary booking data is loaded and handleCreateBooking has not been called
      if (!hasHandledCreateBookingRef.current && tempBookingData && tempBookingData.roomReservationsTempByOrderNumber.length > 0) {
        handleCreateBooking(tempBookingData.roomReservationsTempByOrderNumber[0]);
        hasHandledCreateBookingRef.current = true; // Set the guard variable using the ref
      }
    }
  }, [officialBookingData, tempBookingData]);

  const handleCreateBooking = async (bookingData) => {
    try {
      const modifiedBookingData = {
        ...bookingData,
        paymentStatus: 'paid',
      };

      const { data } = await createBooking({
        variables: modifiedBookingData,
      });

      await axios.post(`/api/booking`, data, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

    } catch (error) {
      console.error(error);
    }
  };

  if (officialBookingLoading || tempBookingLoading) {
    return <div>Loading...</div>;
  }
  const sampleEmail = 'example@example.com';
  const phoneNumber = '809-986-0356';

  const backgroundStyle = {
    backgroundImage: 'url("https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/slider%2Fcafe-colao.jpg?alt=media&token=94e9ffca-14db-446d-b1e4-1793f56a77b6")', // Replace with the path to your image
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  let email;

  if (officialBookingData) {
    email = officialBookingData.roomReservationsByOrderNumber[0].email
  }

  return (
    <Container fluid className="confirmation-container" style={backgroundStyle}>
      <Col md={8} className="text-center p-4 rounded shadow-lg" style={{ 
        backgroundColor: 'rgba(255, 255, 255, 0.9)', 
        backdropFilter: 'blur(5px)',
        }}>
        <AiOutlineCheckCircle style={{ color: '#4CAF50', fontSize: '3rem' }} className="mb-3" />
        <h2 className="mb-4" style={{ color: '#333', fontSize: '2rem' }}>Reservation Confirmed</h2>
        <div className="confirmation-details">
          <p className="mb-4" style={{ color: '#555', fontSize: '1.2rem' }}>
            Order Number: <span style={{ color: '#4CAF50', fontWeight: 'bold', fontSize: '1.5rem' }}>{queryParams.get('OrderNumber')}</span>
          </p>
          <p style={{ color: '#555', fontSize: '1.2rem' }}>
            Your reservation is confirmed. Thank you for choosing Cafe Colao Hotel! An email with all the details will be sent to{' '}
            <span style={{ color: '#333', fontWeight: '600' }}>{email ? email : 'your email'}</span>.
          </p>
          <p style={{ color: '#555', fontSize: '1.2rem', marginTop: '20px' }}>
            If you have any questions or need assistance, please contact us at <span style={{ color: '#333', fontWeight: '500' }}>{phoneNumber}</span>.
          </p>
        </div>
      </Col>
    </Container>
  );
};

export default HotelCompletion;



