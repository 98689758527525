import React, { useRef} from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../componentsES/Navbar';
import Hero from '../componentsES/Hero';
import Footer from '../componentsES/Footer';
import Events from '../componentsES/Events';


const Reservations = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
    // Create a ref for the Events component
    const eventsRef = useRef(null);

    // Function to scroll to the Events component
    const scrollToEvents = () => {
      if (eventsRef.current) {
        eventsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };
  
    return (
      <div className='ReservationsContainer'>
        <Helmet>
          <title>Cafe Colao Tours - Explora Jarabacoa Tours</title>
          <meta name="description" content="Cafe Colao Tours Jarabacoa - Explora Jarabacoa como nunca antes con nuestros tours expertamente diseñados en Cafe Colao. Desde aventuras llenas de adrenalina hasta paseos por la naturaleza, tenemos algo para todos" />
          <meta name="keywords" content="Café Colao Tours, Jarabacoa Tours, Café Colao Jarabacoa, Tours en Jarabacoa" />
          <meta property="og:title" content="Cafe Colao Tours - Explora Jarabacoa Tours" />
          <meta property="og:description" content="Cafe Colao Tours Jarabacoa - Explora Jarabacoa como nunca antes con nuestros tours expertamente diseñados en Cafe Colao. Desde aventuras llenas de adrenalina hasta paseos por la naturaleza, tenemos algo para todos" />
          <meta property="og:url" content="https://cafecolaord.com/tours" />
          <meta property="og:type" content="website" />
          <meta name="twitter:title" content="Cafe Colao Tours - Explora Jarabacoa Tours" />
          <meta name="twitter:description" content="Cafe Colao Tours Jarabacoa - Explora Jarabacoa como nunca antes con nuestros tours expertamente diseñados en Cafe Colao. Desde aventuras llenas de adrenalina hasta paseos por la naturaleza, tenemos algo para todos" />
          <meta name="twitter:url" content="https://cafecolaord.com/tours" />
          {/* <link rel="canonical" href={canonicalUrl} /> */}
        </Helmet>
        <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
        <Hero scrollToEvents={scrollToEvents} />
        <div ref={eventsRef}>
          <Events />
        </div>
        <Footer />
      </div>
    );
};

export default Reservations;
