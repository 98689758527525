import React, { useEffect } from 'react';
import CryptoJS from 'crypto-js';

const Azul = ({submit, orderNumber, totalPrice, type}) => {
  const currentUrl = window.location.origin;

  useEffect(() => {
    calculateAuthHash();

    if (submit) {
      submitForm();
    }
  }, [submit]);

  const calculateAuthHash = () => {
    const hashString =
      '39506990001' +
      'Cafe Colao' +
      'ECommerce' +
      '$' +
      `${orderNumber}` +
      `${totalPrice * 100}` +
      '000' +
      `${currentUrl}/${type}/completion` +
      `${currentUrl}/${type}/decline` +
      `${currentUrl}/${type}/cancel` +
      '0' +
      '' +
      '' +
      '0' +
      '' +
      '' +
      'yOAHnwQqZ8brF4DLp8H4OdOWr4a1e7fOytQJLBmLmEApkzRWUXa1CyQHnnOKVDvCVF8NN0n2E3pDs2Qt67jUSPm1tM570BZ058bEnPd2rygrpEb213d2ZyZfLjfZ6eyF';

    const authHash = CryptoJS.HmacSHA512(hashString, 'yOAHnwQqZ8brF4DLp8H4OdOWr4a1e7fOytQJLBmLmEApkzRWUXa1CyQHnnOKVDvCVF8NN0n2E3pDs2Qt67jUSPm1tM570BZ058bEnPd2rygrpEb213d2ZyZfLjfZ6eyF').toString();

    document.getElementById('AuthHash').value = authHash;
  };

  const handleSubmit = () => {
    calculateAuthHash()
  };

  const submitForm = () => {
    const form = document.getElementById('paymentForm');
    form.submit();
  };

  return (
    <form action="https://pagos.azul.com.do/PaymentPage/Default.aspx" method="POST" id="paymentForm" name="paymentForm" onSubmit={handleSubmit}>
      <input type="hidden" id="MerchantId" name="MerchantId" value="39506990001" />
      <input type="hidden" id="MerchantName" name="MerchantName" value="Cafe Colao" />
      <input type="hidden" id="MerchantType" name="MerchantType" value="ECommerce" />
      <input type="hidden" id="CurrencyCode" name="CurrencyCode" value="$" />
      <input type="hidden" id="OrderNumber" name="OrderNumber" value={orderNumber} />
      <input type="hidden" id="Amount" name="Amount" value={totalPrice * 100} />
      <input type="hidden" id="ITBIS" name="ITBIS" value="000" />
      <input type="hidden" id="ApprovedUrl" name="ApprovedUrl" value={currentUrl + "/" + type + "/completion"} />
      <input type="hidden" id="DeclinedUrl" name="DeclinedUrl" value={currentUrl + "/" + type + "/decline"} />
      <input type="hidden" id="CancelUrl" name="CancelUrl" value={currentUrl + "/" + type + "/cancel"} />
      <input type="hidden" id="UseCustomField1" name="UseCustomField1" value="0" />
      <input type="hidden" id="CustomField1Label" name="CustomField1Label" value="" />
      <input type="hidden" id="CustomField1Value" name="CustomField1Value" value="" />
      <input type="hidden" id="UseCustomField2" name="UseCustomField2" value="0" />
      <input type="hidden" id="CustomField2Label" name="CustomField2Label" value="" />
      <input type="hidden" id="CustomField2Value" name="CustomField2Value" value="" />
      <input type="hidden" id="AuthHash" name="AuthHash" value="" />
    </form>
  );
};

export default Azul;