import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Carousel from '../components/Carousel';
import Footer from '../components/Footer';
import Rooms from '../components/Rooms';


const hotelPageImages = [
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel4.jpg?alt=media&token=d2b18cca-2652-44f8-b025-d55638dc5229',
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel2.jpg?alt=media&token=32f6338c-d427-4dc7-9896-1e6fd207ed2c',
  'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/hotel%2Fcafecolaohotel.jpg?alt=media&token=59894884-add9-4ac1-a9ca-3f95ccb6647f',
  // Add more image URLs here
];

const hotelPageCaptions = [
  'You should come and taste for yourself',
  'Traditional dishes with local products of highest quality',
  'Discover and experience fine selection of international tastes',
  // Add captions corresponding to your images
];


const tagline = `Signature Blend of Comfort and Culture`
const title = `Hotel <br/> Café Colao`

const Hotel = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {

  const eventsRef = useRef(null);

  // Function to scroll to the Events component
  const scrollToEvents = () => {
    if (eventsRef.current) {
      eventsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

    return (
      <div className='hotelContainer'>
      <Helmet>
        <title>Cafe Colao Hotel - Explore Hotel Jarabacoa - English</title>
        <meta name="description" content="Enjoy an unforgettable stay at our cozy hotel in Jarabacoa. With comfortable rooms, stunning views, and quality services, your perfect getaway starts here! Cafe Colao Hotel Jarabacoa" />
        <meta name="keywords" content="Cafe Colao Hotel, Jarabacoa Hotel, Cafe Colao Jarabacoa, Hotel in Jarabacoa, Hotel Cafe Colao" />
        <meta property="og:title" content="Cafe Colao Hotel - Explore Hotel Jarabacoa - English" />
        <meta property="og:description" content="Enjoy an unforgettable stay at our cozy hotel in Jarabacoa. With comfortable rooms, stunning views, and quality services, your perfect getaway starts here! Cafe Colao Hotel Jarabacoa" />
        <meta property="og:url" content="https://cafecolaord.com/hotel" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Cafe Colao Hotel - Explore Hotel Jarabacoa - English" />
        <meta name="twitter:description" content="Enjoy an unforgettable stay at our cozy hotel in Jarabacoa. With comfortable rooms, stunning views, and quality services, your perfect getaway starts here! Cafe Colao Hotel Jarabacoa" />
        <meta name="twitter:url" content="https://cafecolaord.com/hotel" />
        {/* <link rel="canonical" href={canonicalUrl} /> */}
      </Helmet>
        <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
        <Carousel title={title} tagline={tagline} images={hotelPageImages} captions={hotelPageCaptions} scrollToEvents={scrollToEvents} />
        <div ref={eventsRef}>
          <Rooms/>
        </div>
        <Footer/>
      </div>
    )
};

export default Hotel;
