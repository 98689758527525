import React from "react";

const About = () => {
  return (
    <section className="about-section">
        <div className="about-container">
            <div className="about-content">
                <h4 className="subtitle">HISTORIA</h4>
                <h3 className="heading">SOBRE NOSOTROS</h3>
                <div className="title">Cafe Colao Jarabacoa. Bienvenidos a Cafe Colao, donde descubrirás el corazón de Jarabacoa en una taza.</div>
                <div className="text-container">
                <p>Ubicado en el corazón de Jarabacoa, República Dominicana, Cafe Colao es el lugar donde la pasión y la cultura se entrelazan. No somos solo un restaurante o cafetería, somos una celebración de la rica herencia de nuestro pueblo.</p>
                <p>Nuestro viaje comenzó con una idea simple y un profundo amor por Jarabacoa. Hoy, Cafe Colao es un refugio culinario, un espacio acogedor tanto para los lugareños como para los viajeros que desean disfrutar de los sabores tradicionales de la República Dominicana y explorar gustos globales. Únete a nosotros en esta increíble aventura para explorar el corazón de nuestra comunidad, una taza de café a la vez.</p>
                </div>
                <a href="/" className="link">Aprende Más</a>
            </div>
            <div className="image-container">
                <div className="about-img"/>
            </div>
        </div>
    </section>
  );
};

export default About;
