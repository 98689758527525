import React from "react";

const ReservationSummary = ({ title, cover, checkIn, checkOut, breakfast, price, ac}) => {
  const coverImage = cover

  let breakfastPrice;

  
  const checkInNew = new Date(checkIn);
  const checkOutNew = new Date(checkOut);
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const nights = Math.round(Math.abs((checkInNew - checkOutNew) / oneDay));
  
  if (breakfast === "true") {
    breakfastPrice = 1000;
  } else {
    breakfastPrice = 0;
  }

  function formatDate(inputDate) {
    // Split the input date into components (month, day, year)
    const dateComponents = inputDate.split('/');
  
    // Create a JavaScript Date object
    const jsDate = new Date(dateComponents[2], dateComponents[0] - 1, dateComponents[1]);
  
    // Define the options for formatting the date
    const options = { weekday: 'short', month: 'short', day: 'numeric' };
  
    // Format the date
    const formattedDate = jsDate.toLocaleDateString('en-US', options);
  
    return formattedDate;
  }

  const checkInFormatted = formatDate(checkIn);
  const checkOutFormatted = formatDate(checkOut);

  return (
    <section className="booking-summary reservation-summary">
        <div className="summary-header" style={{ backgroundImage: `url(${coverImage})` }}>
          <div className="overlay">
            <h3>{title}</h3>
          </div>
        </div>
        <div className="summary-info">
          <div className="details-title">Detalles de la Reserva</div>
          <div><span>Llegada</span> {checkInFormatted}</div>
          <div><span>Salida</span> {checkOutFormatted}</div>
          <div><span>Noches</span> {nights}</div>
          <div><span>Desayuno</span> {breakfast === "true" ? "Si" : "No"}</div>
          <div className="location"><span>Lugar</span>C. el dorado No. 3, Jarabacoa 41000<br/>Republica Dominicana</div>
          <div className="divider"></div>
          <div className="details-title">Detalles del Precio</div>
          <div><span>Precio </span>RD ${price} x {nights} Noches</div>
          {breakfast === "true" ? (
            <div><span>Desayuno </span>RD ${breakfastPrice} x {nights} Noches</div>
          ) : (
            <span/>
          )}
          <div><span>Impuestos</span> RD $0 (Included)</div>
          <div><span>Tarifa de Servicio</span>RD ${Math.round((parseInt(price) + breakfastPrice) * nights * 0.1)}</div>
          <div className="divider"></div>
          <div className="total-price"><span>Precio Total</span> RD ${Math.round((parseInt(price) + breakfastPrice) * nights * 1.1)}</div>
        </div>
    </section>
  );
};

export default ReservationSummary;