import React from "react";
import { BsClock, BsArrowRightShort, BsSnow } from "react-icons/bs";
import { PiFan } from "react-icons/pi";
import { Link } from "react-router-dom";

const RoomCard = ({ title, images, price, ac, priceId }) => {
  const priceDOP = price;
  return (
    <div className="eventCard">
      <div className="card-container">
        <div className="card-thumbnail">
          <Link to={`/hotel/${priceId}`}>
            <img src={images[0]} alt={title} />
          </Link>
            {ac === 'true' ? (
              <p className="card-label">
                <BsSnow className="card-icon" />
                <span>Aire Acondicionado</span>
              </p>
            ) : (
              <p className="card-label">
                <PiFan className="card-icon" />
                <span>Ventilador de techo</span>
              </p>
            )}
        </div>
        <div className="card-body">
          <h3 className="card-title">
            <Link to={`/hotel/${priceId}`}>{title}</Link>
          </h3>
          <div className="card-bottom">
            <div className="book-button">
              <Link to={`/hotel/${priceId}`}>Reserva <BsArrowRightShort className="arrow-icon" /> </Link>
            </div>
            <div className="card-info">
              <span>Desde</span>
              <h6><span className="currency">RD </span>${priceDOP} <span> noche</span></h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
