import React from "react";

const BookingSummary = ({ title, cover, date, time, duration, price, fourwheels }) => {
  const coverImage = cover;

  function formatDate(inputDate) {
    // Dividir la fecha de entrada en componentes (mes, día, año)
    const dateComponents = inputDate.split('/');

    // Crear un objeto de fecha JavaScript
    const jsDate = new Date(dateComponents[2], dateComponents[0] - 1, dateComponents[1]);

    // Definir las opciones para formatear la fecha
    const options = { weekday: 'short', month: 'short', day: 'numeric' };

    // Formatear la fecha
    const formattedDate = jsDate.toLocaleDateString('es-ES', options);

    return formattedDate;
  }

  const formattedDate = formatDate(date);

  return (
    <section className="booking-summary">
      <div className="summary-header" style={{ backgroundImage: `url(${coverImage})` }}>
        <div className="overlay">
          <h3>{title}</h3>
        </div>
      </div>
      <div className="summary-info">
        <div className="details-title">Detalles de la Reserva</div>
        <div><span>Fecha y Hora</span> {formattedDate} a las {time} </div>
        <div><span>Duración</span> {duration}</div>
        <div><span>Cuatro Ruedas</span> {fourwheels}</div>
        <div><span>Tamaño del Grupo</span> {fourwheels} - {fourwheels * 2}</div>
        <div><span>Precio </span>RD ${Math.round((fourwheels * price) * 0.1)} x Fourwheels</div>
        <div className="location"><span>Ubicación</span>Calle Hermanas Mirabal, Jarabacoa 41000<br/>Republica Dominicana</div>
        <div className="divider"></div>
        <div className="details-title">Detalles del Precio</div>
          <div><span>Impuestos</span> RD $0 (Incluido)</div>
          <div><span>Tarifa de Servicio</span>RD ${Math.round((fourwheels * price) * 0.1)}</div>
        <div className="divider"></div>
        <div className="total-price"><span>Precio Total</span> RD ${Math.round((fourwheels * price) * 1.1)}</div>
      </div>
    </section>
  );
};

export default BookingSummary;
