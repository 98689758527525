import React, {useRef} from 'react';
import { Helmet } from 'react-helmet';
import Navbar from '../components/Navbar';
import Carousel from '../components/Carousel';
import Feature from '../components/Feature';
import About from '../components/About';
import Hours from '../components/Hours';
import Footer from '../components/Footer';
import cafecolao1 from '../images/slider/cafe-colao1.jpg'
import cafecolao2 from '../images/slider/cafe-colao2.jpeg'
import cafecolao3 from '../images/slider/cafe-colao3.jpeg'
import cafecolao4 from '../images/slider/cafe-colao4.jpeg'


const homePageImages = [
  cafecolao1,
  cafecolao3,
  cafecolao4,
];

const homePageCaptions = [
  'You should come and taste for yourself',
  'Traditional dishes with local products of highest quality',
  'Discover and experience fine selection of international tastes',
  // Add captions corresponding to your images
];

const title = "WELCOME"

const Home = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {

    // Create a ref for the Events component
    const eventsRef = useRef(null);

    // Function to scroll to the Events component
    const scrollToEvents = () => {
      if (eventsRef.current) {
        eventsRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    return (
      <div className='HomeContainer'>
      <Helmet>
        <title>Cafe Colao - Explora Jarabacoa - English</title>
        <meta name="description" content="Cafe Colao Jarabacoa - Experience the vibrant culture of Jarabacoa through our restaurant, café, hotel, and city tours. Cafe Colao Jarabacoa" />
        <meta name="keywords" content="Cafe Colao, Jarabacoa, Cafe Colao Jarabacoa, café jarabacoa, restaurante jarabacoa, jarabacoa hotel, jarabacoa tours, calle de sombrillas" />
        <meta property="og:title" content="Cafe Colao - Explora Jarabacoa - English" />
        <meta property="og:description" content="Cafe Colao Jarabacoa - Experience the vibrant culture of Jarabacoa through our restaurant, café, hotel, and city tours. Cafe Colao Jarabacoa" />
        <meta property="og:url" content="https://cafecolaord.com/" />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content="Cafe Colao - Explora Jarabacoa - English" />
        <meta name="twitter:description" content="Cafe Colao Jarabacoa - Experience the vibrant culture of Jarabacoa through our restaurant, café, hotel, and city tours. Cafe Colao Jarabacoa" />
        <meta name="twitter:url" content="https://cafecolaord.com/" />
      </Helmet>
        <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
        <Carousel title={title} images={homePageImages} captions={homePageCaptions} scrollToEvents={scrollToEvents} />
        <div ref={eventsRef}>
          <Feature/>
        </div>
        <About/>
        {/* <Hours/> */}
        <Footer/>
      </div>
    )



};

export default Home;
