import React, { useState } from "react";

const featureItems = [
  // {
  //   image: 'https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/feature-section%2Fwafflescolao.jpg?alt=media&token=73440928-7e07-456f-a9c7-ce8c81c9a4b4',
  //   title: 'Nuestro Menu',
  //   caption: 'Revisa Nuestro Menu',
  //   link: '/menu',
  //   class: 'menu',
  // },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/feature-section%2Ffourwheelerscolao.jpg?alt=media&token=fa65c191-cfbe-400a-b460-928a9afd4282",
    title: "Tour",
    caption: "Reserva un Recorrido",
    link: "/tours",
    class: "booking",
  },
  {
    image:
      "https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/feature-section%2Fmesacolao.jpg?alt=media&token=70a35972-5d57-4506-a8aa-9162667d1851",
    title: "Hotel",
    caption: "Reserva Una Habitación",
    link: "/hotel",
    class: "gallery",
  },
];

const Feature = () => {
  // Create a state variable to track hover state for each feature item
  const [isHovered, setIsHovered] = useState(
    Array(featureItems.length).fill(false)
  );

  // Handle mouse enter and mouse leave events for each feature item
  const handleMouseEnter = (index) => {
    const updatedHoverState = [...isHovered];
    updatedHoverState[index] = true;
    setIsHovered(updatedHoverState);
  };

  const handleMouseLeave = (index) => {
    const updatedHoverState = [...isHovered];
    updatedHoverState[index] = false;
    setIsHovered(updatedHoverState);
  };

  // Create an array of feature items with dynamic styles
  const featureElements = featureItems.map((item, index) => {
    const style = {
      backgroundImage: isHovered[index]
        ? `linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)), url(${item.image})`
        : `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${item.image})`,
    };

    return (
      <a href={item.link} key={index} className="feature-link">
        <div
          className={`feature-item ${item.class}`}
          style={style}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
        >
          <div className="item-content">
            <h2 className="item-title">{item.title}</h2>
            <div className="item-desc">{item.caption}</div>
          </div>
        </div>
      </a>
    );
  });

  return (
    <section className="feature-section">
      <div className="feature-container">{featureElements}</div>
    </section>
  );
};

export default Feature;
