import React from 'react';

const Tour1 = () => {
  return (
    <div>

      <div className="tour-details-container">

        <div className="tour-details-overview">
          <h2>Descripción general</h2>
          <p>
            Embarca en una emocionante excursión de cuatro ruedas de 30 minutos por la pintoresca ciudad de Jarabacoa. Nuestro recorrido guiado te lleva en un viaje emocionante, pasando por lugares turísticos icónicos y un paisaje impresionante que encapsula la belleza de esta vibrante ciudad.
          </p>
        </div>

        <div className="tour-details-highlights">
          <h2>Aspectos destacados del tour</h2>
          <ul>
            <li>Rutas Escénicas: Recorre la ciudad por senderos todo terreno, experimentando la belleza natural de Jarabacoa desde una perspectiva única.</li>
            <li>Lugares Turísticos: Descubre atracciones turísticas clave mientras nuestros guías expertos te llevan por el corazón de la ciudad.</li>
            <li>Oportunidades para Fotos: Captura momentos memorables en paradas designadas, incluyendo una pausa en el reconocido letrero de Jarabacoa. Disfruta de las impresionantes vistas y toma fotos para conmemorar tu aventura.</li>
          </ul>
        </div>

        <div className="tour-details-details">
          <h2>Detalles</h2>
          <p>Duración: 30 minutos de emoción y exploración continua.</p>
          <p>Inclusiones:</p>
          <ul>
            <li>Recorrido guiado con instructores experimentados.</li>
            <li>Uso de vehículo de cuatro ruedas.</li>
            <li>Equipo de seguridad e información previa.</li>
          </ul>
          <p>Paradas para Fotos:</p>
          <ul>
            <li>Gran Letrero de Jarabacoa: Captura la esencia de tu viaje con una foto en este emblemático lugar.</li>
          </ul>
        </div>

        <div className="tour-details-important">
          <h2>Información Importante</h2>
          <p>Punto de Partida: 49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, República Dominicana</p>
          <p>Requisitos de Edad: Participantes de todas las edades son bienvenidos. Para los niños, se requiere el consentimiento de los padres.</p>
          <p>Medidas de Seguridad: Se proporcionan cascos y equipo de seguridad. Sigue las instrucciones del guía para una experiencia segura y agradable.</p>
        </div>
      </div>
    </div>
  );
};

export default Tour1;
