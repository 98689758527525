import React from "react";
import { useQuery } from "@apollo/client";
import { QUERY_ROOMS } from "../utils/queries";
import RoomCard from "./RoomCard";


const Rooms = () => {
  const { loading, error, data } = useQuery(QUERY_ROOMS);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const rooms = data.rooms;

  return (
    <section className="events-section">
      <div className="events-header">
        <h2>Reserva tu Estadía con Nosotros</h2>
        <p>Descubre un confort y lujo incomparables. Reserva tu escape perfecto en el Hotel Café Colao, donde cada detalle está diseñado para garantizar una estadía memorable. Tu escapada ideal comienza aquí.</p>
      </div>
      <div className="events-container">
        {rooms.map((room) => (
          <RoomCard
            key={room.priceId} // Use a unique key for each card (e.g., room._id)
            title={room.title}
            images={room.images}
            price={room.price}
            ac={room.ac}
            priceId={room.priceId}
          />
        ))}
      </div>
    </section>
  );
};

export default Rooms;