import React, { useState, useEffect } from 'react';
import DatePicker from '../componentsES/DatePicker';

function AdminCalendar({ id, activityDuration }) {
  const currentDate = new Date();
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [selectedDay, setSelectedDay] = useState(currentDate.getDate());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [showFullCalendar, setShowFullCalendar] = useState(false);

  const [selectedTime, setSelectedTime] = useState(null);

  // const [maxFourwheelers, setMaxFourWheelers] = useState();
  const [unavailableWarning, setUnavailableWarning] = useState(false);
  

  const minSelectableDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());



  const handleDateSelect = (year, month, day) => {
    const selectedDate = new Date(year, month, day);
    if (selectedDate < minSelectableDate) {
      return;
    }
    setSelectedYear(year);
    setSelectedMonth(month);
    setSelectedDay(day);
    setShowFullCalendar(false);
  };


  const handleTimeSelect = ({ time, maxFourwheelers }) => {
    setSelectedTime(time);
    localStorage.setItem("bookingTime", JSON.stringify(time));
    localStorage.setItem("maxFourwheelers", maxFourwheelers);

    if (maxFourwheelers === 0) {
      setUnavailableWarning(true);
    } else {
      setUnavailableWarning(false);
    }

  };

  const nextMonth = () => {
    const nextMonthDate = new Date(currentMonth);
    nextMonthDate.setMonth(currentMonth.getMonth() + 1);
    setCurrentMonth(nextMonthDate);
  };

  const prevMonth = () => {
    const prevMonthDate = new Date(currentMonth);
    prevMonthDate.setMonth(currentMonth.getMonth() - 1);
    setCurrentMonth(prevMonthDate);
  };

  const daysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    return new Array(32 - new Date(year, month, 32).getDate()).fill(null).map((_, index) => index + 1);
  };

  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril',
    'Mayo', 'Junio', 'Julio', 'Agosto',
    'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  const calendarDates = daysInMonth();


  return (
    <div id='fullCal' className="date-picker">
      <h2>Seleccione una fecha</h2>
      <button className="show-full-calendar" onClick={() => setShowFullCalendar(!showFullCalendar)}>
        {showFullCalendar ? 'Hide Full Calendar' : 'Show Full Calendar'}
      </button>
      {showFullCalendar ? (
        <div className="calendar">
          <div className="month-navigation">
            <button className="prev-month" onClick={prevMonth}>&lt;</button>
            <div className="current-month">{months[currentMonth.getMonth()]} {currentMonth.getFullYear()}</div>
            <button className="next-month" onClick={nextMonth}>&gt;</button>
          </div>
          <div className="days">
            {calendarDates.map((day, index) => {
              const dateToCheck = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
              const isDisabled = dateToCheck < minSelectableDate;
              const isSelected = !isDisabled && selectedYear === currentMonth.getFullYear() && selectedMonth === currentMonth.getMonth() && selectedDay === day;

              return (
                <button key={index} className={`date-button ${isDisabled ? 'disabled' : ''} ${isSelected ? 'selected' : ''}`} onClick={() => handleDateSelect(currentMonth.getFullYear(), currentMonth.getMonth(), day)}>{day}</button>
              );
            })}
          </div>
        </div>
      ) : (
        <div className='date-picker-section'>
          <DatePicker year={selectedYear} month={selectedMonth} day={selectedDay} selectedTime={selectedTime} onTimeSelect={handleTimeSelect} activityDuration={activityDuration} />
          {/* <button onClick={handleNextPage} className={`next-button ${selectedTime != null ? "" : "disabled"}`}>NEXT</button> */}
          {unavailableWarning ? (
            <div>
              <h3 className='text-danger fw-bold'>Advertencia!</h3>
              <p className='text-danger fw-bold'>Ya hay fourwheelers reservados durante este tiempo</p>
            </div>
          ) : <span/>}
        </div>
      )}
    </div>
  );
}

export default AdminCalendar;
