import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { LOGIN_EMPLOYEE } from '../utils/mutations';

import Auth from '../utils/auth';

const Login = (props) => {
  const [formState, setFormState] = useState({ username: '', password: '' });
  const [login, { error, data }] = useMutation(LOGIN_EMPLOYEE);

  // update state based on form input changes
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormState({
      ...formState,
      [name]: value,
    });
  };

  // submit form
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const { username, password } = formState;
      const lowercaseUsername = username.toLowerCase();
  
      const { data } = await login({
        variables: { username: lowercaseUsername, password },
      });
  
      Auth.login(data.login.token, window.location.pathname);
    } catch (e) {
      console.error(e);
    }
  
    // clear form values
    setFormState({
      username: '',
      password: '',
    });
  };

  return (
    <div className="card-main-container">
      <div className='card-wrapper'>
        <div className="card login-card">
          <h4 className='login-title'>Ingresar</h4>
            {data ? (
              <p>
                Success! You may now head{" "}
                <Link to="/admin">back to the homepage.</Link>
              </p>
            ) : (
            <div className="card-body">
              <form onSubmit={handleFormSubmit}>
                <input
                  className="form-input control"
                  placeholder="Usuario"
                  name="username"
                  type="text"
                  autoComplete='username'
                  value={formState.username}
                  onChange={handleChange}
                />
                <input
                  className="form-input control"
                  placeholder="******"
                  name="password"
                  type="password"
                  autoComplete='current-password'
                  value={formState.password}
                  onChange={handleChange}
                />
                <button className="control" type="submit">
                  Ingresar
                </button>
              </form>
            </div>
            )}

            {error && <div>{error.message}</div>}
        </div>
      </div>
    </div>
  );
};

export default Login;
