import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_ACTIVITY_BY_PRICE_ID } from "../utils/queries";

import BookingPage from "../components/BookingPage";
import Carousel from "../components/Carousel";
import Calendar from "../components/Calendar";

import Tour1 from "../tours/tour1";
import Tour2 from "../tours/tour2";
import Tour3 from "../tours/tour3";

import {
  BsImages,
  BsXLg,
  BsClock,
  BsCalendar2Date,
  BsArrowLeft,
} from "react-icons/bs";
import { FaPeopleGroup, FaChild } from "react-icons/fa6";

import CafeColao from "../images/cafe-colao-logo.png"

const ActivityDetailsPage = () => {
  const [viewAllVisible, setViewAllVisible] = useState(false);
  const { id } = useParams();

  const priceId = id;

  useEffect(() => {
    localStorage.removeItem("bookingTime");
    localStorage.removeItem("bookingData");
    localStorage.setItem("activityId", JSON.stringify(priceId));
  }, []);

  // Add a useEffect to apply/remove overflow: hidden to the body
  useEffect(() => {
    if (viewAllVisible) {
      // When viewAllVisible is true, apply overflow: hidden to the body
      document.body.style.overflow = "hidden";
    } else {
      // When viewAllVisible is false, remove overflow: hidden from the body
      document.body.style.overflow = "auto";
    }

    // Cleanup function to remove the style when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [viewAllVisible]);

  const { loading, error, data } = useQuery(QUERY_ACTIVITY_BY_PRICE_ID, {
    variables: { priceId },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const activity = data.activityByPriceId;
  const activityDuration = activity.duration;

  const toggleViewAll = () => {
    setViewAllVisible(!viewAllVisible);
  };

  let tourComponent;

  // Use a switch statement to determine the tour component based on activity duration
  switch (activityDuration) {
    case "30min":
      tourComponent = <Tour1 />;
      break;
    case "1hr":
      tourComponent = <Tour2 />;
      break;
    case "2hr":
      tourComponent = <Tour3 />;
      break;
    default:
      tourComponent = null; // Handle other cases or provide a default component
      break;
  }

  return (
    <div className="activityDetail">
      <div className="activity-content">
        <div className="backNav">
          <a href="/tours">
            <BsArrowLeft />
            All Activities
          </a>
        </div>
        <div style={{width: "100%", textAlign: "center"}}>
          <a href="/"><img src={CafeColao} alt="Cafe Colao Jarabacoa Logo" style={{ width: "90px" }}/></a>
        </div>
        <section className="photo-array">
          <div style={{ backgroundImage: `url(${activity.images[0]})` }}></div>
          <div style={{ backgroundImage: `url(${activity.images[1]})` }}></div>
          <div style={{ backgroundImage: `url(${activity.images[2]})` }}></div>
          <div style={{ backgroundImage: `url(${activity.images[3]})` }}></div>
          <div
            style={{ backgroundImage: `url(${activity.images[4]})` }}
            onClick={toggleViewAll}
          >
            <div className="view-all">
              <BsImages />
              View All
            </div>
          </div>
        </section>
        {viewAllVisible && (
          <>
            <Carousel images={activity.images} />
            <button onClick={toggleViewAll} className="close-full">
              <BsXLg />
            </button>
          </>
        )}
        <div className="mobile-carousel">
          <Carousel images={activity.images} />
        </div>
        <h1>{activity.title}</h1>
        <div className="desc-cards">
          {/* Duration Card */}
          <div className="desc-card">
            <BsClock />
            <div className="text">
              <h3>Duration</h3>
              <p>{activity.duration}</p>
            </div>
          </div>

          {/* Max People Card */}
          {/* <div className="desc-card">
            <FaPeopleGroup/>
            <div className="text">
              <h3>Max People</h3>
              <p>12</p>
            </div>
          </div> */}

          {/* Min Age */}
          {/* <div className="desc-card">
            <FaChild/>
            <div className="text">
              <h3>Age</h3>
              <p>7+</p>
            </div>
          </div> */}
        </div>

        {/* <Calendar id={id} activityDuration={activityDuration} /> */}
        {tourComponent}
      </div>
    </div>
  );
};

export default ActivityDetailsPage;
