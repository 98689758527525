import React from 'react';

function TimePicker({ selectedTime, onTimeSelect, timeOptions, bookedTimes, activityDuration }) {
  const totalFourwheels = 7;
  let durationIndexes;

  switch (activityDuration) {
    case "30min":
      durationIndexes = 1; // 30 minutes is 0.5 hours
      break;
    case "1hr":
      durationIndexes = 2;
      break;
    case "2hr":
      durationIndexes = 4;
      break;
    default:
      break;
  }



// Map through bookedTimes to create a new array
const newBookedTimes = bookedTimes.flatMap((booking, index) => {

  const { time, duration, fourwheels } = booking;

  // Perform any necessary calculations or modifications
  const modifiedBooking1 = {
    time,
    fourwheels
  };

  let durationAmount;

  switch (duration) {
    case "30min":
      durationAmount = 0.1; // 30 minutes is 0.5 hours
      break;
    case "1hr":
      durationAmount = 0.5;
      break;
    case "2hr":
      durationAmount = 1.5;
      break;
    default:
      break;
  }

  // Calculate the time for each index based on duration
  const modifiedBookings = Array.from({ length: (durationAmount * 2) + 1 }, (_, i) => {
    const [hours, minutes, ampm] = time.match(/(\d+):(\d+) (\w+)/).slice(1);

    // Calculate modified time for each half-hour mark
    let modifiedHour = Math.floor((parseInt(minutes) + i * 30) / 60) + parseInt(hours);
    modifiedHour = modifiedHour % 12 === 0 ? 12 : modifiedHour % 12;

    // Determine whether it's in the morning or afternoon
    const isMorning = modifiedHour >= 9 && modifiedHour <= 11;

    // Adjust AM/PM based on the original time
    const modifiedAmpm = isMorning ? 'AM' : 'PM';

    if (modifiedHour > 12) {
      modifiedHour -= 12;
    } else if (modifiedHour === 0) {
      modifiedHour = 12;
    }

    const modifiedMinutes = (parseInt(minutes) + i * 30) % 60;
    const modifiedTime = `${modifiedHour}:${modifiedMinutes === 0 ? '00' : modifiedMinutes} ${modifiedAmpm}`;

    return {
      time: modifiedTime,
      fourwheels
    };
  });

  // Return an array with all modified bookings
  return [modifiedBooking1, ...modifiedBookings.slice(1)];
});


return (
  <div className="time-picker">
    <h2>Selecciona una Hora</h2>
    {timeOptions.length === 0 ? (
        <p>No hay opciones horarias disponibles para el día seleccionado.</p>
      ) : (
    <ul className="time-options">
      {timeOptions.map((time, index) => {
        // Find all bookings for the current time
        const bookingsForTime = newBookedTimes.filter((booking) => booking.time === time);

        // Calculate the total number of fourwheels booked for the current time
        const totalBookedFourwheels = bookingsForTime.reduce((total, booking) => total + parseInt(booking.fourwheels, 10), 0);

        // Calculate the available fourwheels for the current time
        const availableFourwheels = Math.max(0, totalFourwheels - totalBookedFourwheels);

        // Check if the time is fully booked
        const isFullyBooked = totalBookedFourwheels >= totalFourwheels;

        // Create an array with the amount of fourwheelers for the selected time and the next two time options
        const fourwheelersArray = timeOptions.slice(index, index + durationIndexes).map((t) => {
          const bookings = newBookedTimes.filter((booking) => booking.time === t);
          const total = bookings.reduce((total, booking) => total + parseInt(booking.fourwheels, 10), 0);
          return Math.max(0, totalFourwheels - total);
        });

        const nextOptionsCount = durationIndexes;
        const nextOptionsStartIndex = timeOptions.indexOf(selectedTime);
        const isNextOptions = index >= nextOptionsStartIndex && index < nextOptionsStartIndex + nextOptionsCount;
        return (
          <li key={index}>
            <button
              className={`time-option ${selectedTime === time ? 'selected' : ''} ${isFullyBooked ? 'unavailable' : ''} ${isNextOptions ? 'selected' : ''}`}
              onClick={() => !isFullyBooked && onTimeSelect(time, fourwheelersArray)} // Only call onTimeSelect if it's not fully booked
              aria-selected={selectedTime === time}
              disabled={isFullyBooked} // Disable fully booked times
            >
              {time}<p> {isFullyBooked ? 'Completamente Reservado' : `Fourwheelers Disponibles: ${availableFourwheels}`}</p>
            </button>
          </li>
        );
      })}
    </ul>
      )}
  </div>
);
}

export default TimePicker;