import React from 'react';
import Navbar from '../components/Navbar';

const Terms = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  return (
    <div className="terms-container policies-container">
      <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
      <div className="terms-content policies-content">
        <h1>Terms and Conditions</h1>

        <p>
          <strong>1. Acceptance of Terms</strong>
          <br />
          1.1. By accessing and using the services provided by Cafe Colao, you agree to comply with and be bound by these terms and conditions.
        </p>

        <p>
          <strong>2. Booking and Reservation</strong>
          <br />
          2.1. Reservations for services offered by Cafe Colao can be made through our website.
          <br />
          2.2. A valid credit card may be required to secure and confirm reservations.
          <br />
          2.3. Prices and availability are subject to change without notice.
          <br />
          2.4. Cafe Colao reserves the right to refuse or cancel reservations for any reason.
        </p>

        <p>
          <strong>3. Payment</strong>
          <br />
          3.1. Payment for services must be made in full at the time of booking.
        </p>

        <p>
          <strong>4. Cancellation and Changes Policy</strong>
          <br />
          4.1. Cancellations are not allowed, and refunds will not be provided.
          <br />
          4.2. Changes to reservations are subject to availability and may incur additional charges.
          <br />
          4.3. Requests for changes must be made within 72 hours of the scheduled service.
        </p>

        <p>
          <strong>5. Customer Responsibilities</strong>
          <br />
          5.1. Customers are responsible for providing accurate and up-to-date information during the reservation process.
          <br />
          5.2. Customers are expected to adhere to any guidelines or instructions provided by Cafe Colao during the service.
        </p>

        <p>
          <strong>6. Privacy Policy</strong>
          <br />
          6.1. Cafe Colao respects the privacy of its customers. Please refer to our <a href='/policies' target='_blank'>Privacy Policy</a> for details on how we collect, use, and protect your personal information.
        </p>

        <p>
          <strong>7. Limitation of Liability</strong>
          <br />
          7.1. Cafe Colao is not liable for any injury, loss, damage, delay, or inconvenience caused during the provision of services.
        </p>

        <p>
          <strong>8. Contact Information</strong>
          <br />
          8.1. For questions or concerns regarding these terms and conditions, please contact us at 809-986-0356 or 809-761-0793.
        </p>

        <p>
          By proceeding with the reservation, you acknowledge that you have read, understood, and agree to these terms and conditions.
        </p>
      </div>
    </div>
  );
};

export default Terms;