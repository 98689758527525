import React from "react";

const About = () => {
  return (
    <section className="about-section">
        <div className="about-container">
            <div className="about-content">
                <h4 className="subtitle">HISTORY</h4>
                <h3 className="heading">ABOUT US</h3>
                <div className="title">Cafe Colao Jarabacoa. Welcome to Cafe Colao, where you will discover the heart of Jarabacoa in a cup.</div>
                <div className="text-container">
                <p>Located in the heart of Jarabacoa, Dominican Republic, Cafe Colao is where passion and culture come together. We're not just a restaurant or cafe, we're a celebration of our town's rich heritage.</p>
                <p>Our journey started with a simple idea and a deep love for Jarabacoa. Today, Cafe Colao is a culinary haven, a welcoming space for locals and travelers to savor traditional Dominican flavors and explore global tastes. Join us in this incredible adventure to explore the heart of our community, one cup of coffee at a time.</p>
                </div>
                <a href="/" className="link">Learn More</a>
            </div>
            <div className="image-container">
                <div className="about-img"/>
            </div>
        </div>
    </section>
  );
};

export default About;
