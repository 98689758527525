import React from 'react';
import Navbar from '../components/Navbar';

const Policies = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  return (
    <div className="policies-container">
      <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
      <div className="policies-content">
        <h1>Privacy Policy</h1>

        <p>
          Welcome to Café Colao! Your privacy is important to us, and we are committed to safeguarding your personal information.
          This Privacy Policy outlines how we collect, use, and protect the data you share with us.
        </p>

        <h2>1. Information We Collect:</h2>
        <p>
        We collect personal information, such as your name, contact details, and payment information when you make a reservation for our services. It's important to note that we do NOT store or share any card information or other sensitive data. Additionally, we may gather non-personal data related to your interactions on our website, including cookies and usage patterns.
        </p>

        <h2>2. How We Use Your Information:</h2>
        <p>
          Your information is used to process reservations, improve our services, and personalize your experience with Café Colao. We may also use your data for marketing purposes, such as providing you with special offers or updates about our services. Rest assured, your information will not be sold or shared with third parties for unrelated purposes.
        </p>

        <h2>3. Security:</h2>

        <p>
          <strong>Website:</strong>
          <br />
          We take all reasonable measures and precautions to protect your personal information and follow industry best practices to ensure that your information is not used inappropriately, altered, or destroyed.
        </p>

        <p>
          <strong>Payments:</strong>
          <br />
          The payment methods used by Café Colao are third-party services. These third-party services (AZUL) adhere to all security and encryption standards to keep your information secure. They will only use the necessary information to complete the required process. We also recommend reading the Privacy Policies of these providers to better understand how they handle the supplied information.
        </p>

        <h2>4. Third-Party Services:</h2>
        <p>
          Café Colao may use third-party services to enhance our website's functionality and improve user experience. These services may have their own privacy policies, and we encourage you to review them. However, your personal information will not be shared with third parties without your consent, except as required by law.
        </p>

        <h2>5. Your Choices:</h2>
        <p>
          You have the right to control the information you provide to Café Colao. You can update your preferences, request access to your data, or opt-out of marketing communications at any time. Simply contact our customer support team, and we will assist you promptly.
        </p>

        <h2>6. Cookies:</h2>
        <p>
          Our website uses cookies to enhance your browsing experience. You can manage your cookie preferences through your browser settings. Please note that disabling cookies may impact the functionality of certain features on our website.
        </p>

        <h2>7. Returns, Refunds, and Cancellations:</h2>
        <p>
          **Returns:**
          Due to the nature of our services being reservations for time-sensitive experiences, we regret to inform you that returns are not applicable. We are committed to delivering unforgettable moments, and once a reservation is confirmed, it cannot be returned to the merchant.
        </p>

        <p>
          **Refunds:**
          Similarly, we do not offer refunds for confirmed reservations. This policy is in place due to the intricate planning and resources involved in preparing for your selected experience. We appreciate your understanding that our commitment to delivering quality services requires careful scheduling and preparation.
        </p>

        <p>
          **Cancellations:**
          Café Colao understands that plans may change. While refunds are not provided, we may offer flexibility with rescheduling or providing credits for future experiences, depending on the circumstances. Please contact our customer support for assistance in such cases.
        </p>

        <h2>8. Service Delivery Instructions:</h2>
        <p>
          The unique experiences we offer are delivered through personalized services rather than physical products. Here's how our service delivery works:
        </p>

        <p>
          8.1. Upon completing your booking, you will receive a booking confirmation containing essential details about your tour or reservation.
        </p>

        <p>
          8.2. On the scheduled date and time of your booking, please proceed to the specified location mentioned in your confirmation email.
        </p>

        <p>
          8.3. Present the booking information to our dedicated staff, which may include your confirmation number, name, or phone number.
        </p>

        <p>
          8.4. Following the verification process, our staff will provide you with instructions for the next steps in your experience.
        </p>

        <p>
          8.5. Any relevant documents or confirmations will be sent to you electronically via the email address associated with your reservation.
        </p>

        <p>
          Please ensure to follow these steps to ensure a smooth and enjoyable experience with Café Colao. If you have any questions or concerns regarding the service delivery, feel free to contact us for any help or questions.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about our privacy policy, please contact our customer support team.
        </p>

        <p>
          Contact Information:
          <br />
          Customer Support Email
          contacto@colao.com
          <br />
          Customer Support Phone Number
          809-986-0356
          809-761-0793
        </p>
      </div>
    </div>
  );
};

export default Policies;