import { gql } from '@apollo/client';


export const QUERY_EMPLOYEE = gql`
  query employee($employeeId: ID!) {
    employee(employeeId: $employeeId) {
      _id
      username
      firstName
      lastName
      role
    }
  }
`;

export const QUERY_EMPLOYEES = gql`
  query getEmployees {
    employees {
      _id
      username
      firstName
      lastName
      password
      role
    }
  }
`;

export const QUERY_ME = gql`
  query me {
    me {
      _id
      username
      firstName
      lastName
      role
    }
  }
`;


export const QUERY_CUSTOMERS = gql`
  query getCustomers {
    customers {
      _id
      firstName
      lastName
      email
    }
  }
`;


export const QUERY_ACTIVITIES = gql`
  query getActivities {
    activities {
      _id
      title
      images
      duration
      price
      priceId
    }
  }
`;


export const QUERY_ACTIVITY_BY_PRICE_ID = gql`
  query getActivityByPriceId($priceId: String!) {
    activityByPriceId(priceId: $priceId) {
      _id
      title
      images
      duration
      price
      priceId
    }
  }
`;

export const QUERY_ROOMS = gql`
  query getRooms {
    rooms {
      _id
      title
      occupancy
      ac
      images
      price
      roomCount
      rooms
      priceId
    }
  }
`;

export const QUERY_ROOM_BY_PRICE_ID = gql`
  query getRoomByPriceId($priceId: String!) {
    roomByPriceId(priceId: $priceId) {
      _id
      title
      images
      ac
      price
      priceId
    }
  }
`;

export const QUERY_ROOM_RESERVATIONS = gql`
  query getRoomReservations {
    roomReservations {
      roomId
      roomNumber
      roomTitle
      orderNumber
      price
      paymentStatus
      breakfast
      checkInDate
      checkOutDate
      firstName
      lastName
      email
      phone
    }
  }
`;

export const QUERY_RESERVATIONS_BY_ORDER_NUMBER = gql`
  query getReservationsByOrderNumber($orderNumber: String!) {
    roomReservationsByOrderNumber(orderNumber: $orderNumber) {
      roomId
      roomNumber
      roomTitle
      orderNumber
      price
      paymentStatus
      breakfast
      checkInDate
      checkOutDate
      firstName
      lastName
      email
      phone
    }
  }
`;

export const QUERY_RESERVATIONS_TEMP_BY_ORDER_NUMBER = gql`
  query getReservationsTempByOrderNumber($orderNumber: String!) {
    roomReservationsTempByOrderNumber(orderNumber: $orderNumber) {
      roomId
      roomNumber
      roomTitle
      orderNumber
      price
      paymentStatus
      breakfast
      checkInDate
      checkOutDate
      firstName
      lastName
      email
      phone
    }
  }
`;

export const QUERY_RESERVATIONS_BY_ROOM_ID = gql`
  query getReservationsByRoomId($roomId: String!) {
    roomReservationsByRoomId(roomId: $roomId) {
      roomId
      roomNumber
      roomTitle
      orderNumber
      price
      paymentStatus
      breakfast
      checkInDate
      checkOutDate
      firstName
      lastName
      email
      phone
    }
  }
`;

export const QUERY_BOOKINGS = gql`
  query getBookings {
    bookings {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;


export const QUERY_BOOKINGS_BY_DATE = gql`
  query getBookingsByDate($date: String!) {
    bookingsByDate(date: $date) {
      _id
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      activityId
    }
  }
`;

export const QUERY_BOOKINGS_BY_ORDER_NUMBER = gql`
  query getBookingsByOrderNumber($orderNumber: String!) {
    bookingsByOrderNumber(orderNumber: $orderNumber) {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;

export const QUERY_BOOKINGS_TEMP_BY_ORDER_NUMBER = gql`
  query getBookingsTempByOrderNumber($orderNumber: String!) {
    bookingsTempByOrderNumber(orderNumber: $orderNumber) {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;