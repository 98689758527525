import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import TimePicker from './TimePicker';
import { QUERY_BOOKINGS_BY_DATE } from '../utils/queries';

function DatePicker({ year, month, day, selectedTime, onTimeSelect, activityDuration }) {
  // Create a new Date object using the year, month, and day props
  const [selectedDate, setSelectedDate] = useState(new Date(year, month, day));
  const [maxFourwheelers, setMaxFourWheelers] = useState();

  const formatDate = (date) => {
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  };

  const { loading, error, data, refetch } = useQuery(QUERY_BOOKINGS_BY_DATE, {
    variables: { date: formatDate(selectedDate) },
  });

  let bookedTimes = [];

  if (!loading && !error) {
    const bookingsData = data.bookingsByDate;
    bookedTimes = bookingsData.map((booking) => ({
      time: booking.time,
      duration: booking.duration,
      fourwheels: booking.fourwheels, // Assuming this is the property containing the count of fourwheels booked
    }));
  }

  const timeOptions = [
    '9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM', '11:30 AM',
    '12:00 PM', '12:30 PM', '1:00 PM', '1:30 PM', '2:00 PM', '2:30 PM', '3:00 PM', '3:30 PM', '4:00 PM', '4:30 PM', '5:00 PM', '5:30 PM',
  ];

  let futureTimeOptions;

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours();
    const isPM = hours >= 12;
  
    // Convert 24-hour format to 12-hour format
    return `${hours % 12 || 12}:00 ${isPM ? 'PM' : 'AM'}`;
  };
  
  const isCurrentDate = selectedDate.toDateString() === new Date().toDateString();
  // const currentTime = "8:00 PM"; // For testing purposes, you can replace this with getCurrentTime()
  const currentTime = getCurrentTime(); // For testing purposes, you can replace this with getCurrentTime()

  if (currentTime == "8:00 AM") {
    const startIndex = 2
    // Adjust the start index by adding 2 to exclude the first two indexes
    
    // Slice the time options based on the start index and cutoff time
    futureTimeOptions = timeOptions.slice(startIndex)
  } else {
    const currentIndex = timeOptions.indexOf(currentTime);
    
    // Specify times where no future time options should be displayed
    const exclusionTimes = ['6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'];
    
    // Set a cutoff time (e.g., 6:00 PM) for showing only 4 hours ahead
    const cutoffTime = 18; // 6:00 PM in 24-hour format
    
    // Check if the current time is in the exclusion list
    const displayFutureOptions = !exclusionTimes.includes(currentTime);
    
    // Calculate the start index based on the exclusion check
    const startIndex = displayFutureOptions && currentIndex !== -1 ? Math.min(currentIndex + 4, cutoffTime) : 0;
    // Adjust the start index by adding 2 to exclude the first two indexes
    
    // Slice the time options based on the start index and cutoff time
    futureTimeOptions = displayFutureOptions ? timeOptions.slice(startIndex, cutoffTime + 1) : [];
    
  }
  // Function to handle time selection
  const handleTimeSelection = (time, fourwheelers) => {
    // Pass an object with time and maxFourwheelers to the parent component
    if (fourwheelers) {
      onTimeSelect({ time, maxFourwheelers: Math.min(...fourwheelers) });
      const lowestFourwheelers = Math.min(...fourwheelers);
      setMaxFourWheelers(lowestFourwheelers);
    }
  };

const getNext3Days = () => {
  const dateOptions = [];
  const middleIndex = 1; // Middle index for the 3-day array

  const currentDate = new Date(); // Get the current date
  currentDate.setHours(0, 0, 0, 0); // Set the time to midnight

  for (let i = 0; i < 3; i++) {
    const nextDay = new Date(selectedDate); // Create a copy of the selected date
    nextDay.setDate(selectedDate.getDate() + (i - middleIndex)); // Set the date based on the middle index
    nextDay.setHours(0, 0, 0, 0); // Set the time to midnight

    const day = nextDay.toLocaleString('es-ES', { weekday: 'short' });
    const date = nextDay.getDate();
    const month = nextDay.toLocaleString('es-ES', { month: 'short' });

    // Determine if the current date should be highlighted
    const isCurrentDate = nextDay.toDateString() === selectedDate.toDateString();

    // Check if the date is before the current date
    const isPastDate = nextDay < currentDate;

    dateOptions.push({ day, date, month, isCurrentDate, isPastDate });
  }

  return dateOptions;
};

  const dateOptions = getNext3Days();

  // Store the selected date in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("bookingDate", JSON.stringify(formatDate(selectedDate)));
    handleTimeSelection(null);
    refetch();
  }, [selectedDate, refetch]);

  return (
    <div className="date-picker">
      <h2>Elige una Fecha</h2>
      <ul className="date-options">
        {dateOptions.map((option, index) => (
        <li key={index}>
          <button
            className={`date-option ${
              selectedDate.getDate() === option.date ? 'selected' : ''
            } ${option.isCurrentDate ? 'current-date' : ''} ${option.isPastDate ? 'unavailable' : ''}`}
            onClick={() => setSelectedDate(new Date(selectedDate.getFullYear(), selectedDate.getMonth(), option.date))}
            aria-selected={selectedDate.getDate() === option.date}
            disabled={option.isPastDate} 
          >
            <div className="day">{option.day}</div>
            <div className="date">{option.date}</div>
            <div className="month">{option.month}</div>
          </button>
        </li>
        ))}
      </ul>
      {/* Display the selected date */}
      <div className="selected-date">
        {selectedDate && (
          <p>Fecha Seleccionada: {selectedDate.toLocaleDateString('es-ES', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
        )}
      </div>

      <TimePicker timeOptions={isCurrentDate ? futureTimeOptions : timeOptions} selectedTime={selectedTime} selectedDate={selectedDate} onTimeSelect={handleTimeSelection} bookedTimes={bookedTimes} activityDuration={activityDuration}/>

      {/* Display the selected time */}
      <div className="selected-time">
        {selectedTime && (
          <div>
            <p>Hora Seleccionada: {selectedTime}</p>
            {maxFourwheelers < 1 ? (
              <p>Ya hay fourwheelers reservados durante el tiempo seleccionado</p>
            ) : (
              <p>Fourwheelers disponibles: {maxFourwheelers}</p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default DatePicker;
