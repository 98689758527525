import React, {useState, useEffect} from 'react';
import io from 'socket.io-client'
import { Navigate, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import AdminCalendar from './adminCalendar';

import { generateOrderNumber } from '../utils/orderNumber';

import { QUERY_EMPLOYEE, QUERY_ME, QUERY_BOOKINGS, QUERY_ACTIVITIES } from '../utils/queries';
import { ADD_BOOKING } from '../utils/mutations';

import 'bootstrap/dist/css/bootstrap.min.css';

import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';

import { MdLightMode, MdDarkMode } from "react-icons/md";

import Auth from '../utils/auth';



const Dashboard = () => {
  const { username: userParam } = useParams();

  const[createBooking, { error, newBookingData }] = useMutation(ADD_BOOKING);

  const { loading, data } = useQuery(userParam ? QUERY_EMPLOYEE : QUERY_ME, {
    variables: { username: userParam },
  });

  const employee = data?.me || data?.employee || {};

  const { loadingBooking, data: dataBooking, refetch: refetchBookings } = useQuery(QUERY_BOOKINGS);
  const allBookings = dataBooking?.bookings || []

  const { loadingActivities, data: dataActivies } = useQuery(QUERY_ACTIVITIES);
  const allActivities = dataActivies?.activities || []

  const [selectedTour, setSelectedTour] = useState(null);
  const [selectedTourDuration, setSelectedTourDuration] = useState(null);
  const [selectedTourTitle, setSelectedTourTitle] = useState(null);

  const [adminCalendarKey, setAdminCalendarKey] = useState(0);

  const [totalCost, setTotalCost] = useState(null);
  const [selectedTourPrice, setselectedTourPrice] = useState(null);


  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    fourwheels: '',
    people: '',
    phone: '',
    email: '',
  });

  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const [searchInputs, setSearchInputs] = useState({});

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const orderNumber = generateOrderNumber();
  
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalFourWheelers, setTotalFourWheelers] = useState(0);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleCloseDetail = () => setShowDetail(false);
  const handleShowDetail = () => setShowDetail(true);
  
  const [selectedBookingDetail, setSelectedBookingDetail] = useState(null);

  const toggleMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    localStorage.setItem('darkMode', newMode);
  };

  const handleTourSelection = (activity, duration, activityPrice, activityTitle) => {
    setSelectedTour(activity);
    setSelectedTourDuration(duration);
    setselectedTourPrice(activityPrice)
    setSelectedTourTitle(activityTitle)
    localStorage.setItem("tourId", JSON.stringify(activity));
    setTotalCost(formData.fourwheels * activityPrice)

    setAdminCalendarKey((prevKey) => prevKey + 1);
  };

  const calculateActivityData = (activityId, price) => {
    let totalRevenue = 0;
    let totalFourWheelers = 0;
  
    // Iterate through bookings
    allBookings.forEach((booking) => {
      if (booking.activityId === activityId) {
        // Calculate revenue for this booking (price per four-wheeler * number of four-wheelers)
        const revenue = price * booking.fourwheels;
        totalRevenue += revenue;
  
        // Accumulate the total number of four-wheelers
        totalFourWheelers += parseInt(booking.fourwheels, 10);
      }
    });
  
    return { totalRevenue, totalFourWheelers };
  };

  useEffect(() => {
    // Replace 'your-heroku-app-name' with the actual name of your Heroku app
    const socket = io('https://cafecolaord.com', { transports: ['polling'] });

    socket.on('newBooking', (socketData) => {
      refetchBookings();
    });

    const storedMode = localStorage.getItem('darkMode');
    if (storedMode !== null) {
      setIsDarkMode(storedMode === 'true');
    }

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    let totalRevenue = 0;
    let totalFourWheelers = 0;
  
    // Iterate through all activities and sum up their revenues and total four-wheelers
    allActivities.forEach((activity) => {
      const activityData = calculateActivityData(activity.priceId, activity.price);
      totalRevenue += activityData.totalRevenue;
      totalFourWheelers += activityData.totalFourWheelers;
    });
  
    // Update the state with the formatted total revenue and total four-wheelers
    setTotalRevenue(totalRevenue);
    setTotalFourWheelers(totalFourWheelers);
  
  }, [allActivities, allBookings]); // Include allBookings as a dependency


  // navigate to personal profile page if username is yours
  if (Auth.loggedIn() && Auth.getProfile().data.username === userParam) {
    return <Navigate to="/admin" />;
  }
  
  const loadingOrError = loading || loadingBooking || loadingActivities;

  if (loadingOrError) {
    return <h1>LOADING...</h1>;
  }

  if (!employee?.username) {
    window.location.href = '/admin/login';
  }


  const columns = [
    { Header: 'Reserva', accessor: 'orderNumber' },
    { Header: 'Cliente', accessor: 'firstName' },
    { Header: 'Fecha', accessor: 'date' },
    { Header: 'Tiempo', accessor: 'time' },
    { Header: 'Fourwheels', accessor: 'fourwheels' },
    // { Header: 'Teléfono', accessor: 'phone' },
    // { Header: 'Correo Electrónico', accessor: 'email' },
  ];

  const handleSearchInputChange = (columnKey, value) => {
    setSearchInputs((prevSearchInputs) => ({
      ...prevSearchInputs,
      [columnKey]: value,
    }));
  };

  const filteredBookings = allBookings.filter((booking) =>
  columns.every((column) => {
    const { accessor } = column;
    const searchValue = searchInputs[accessor] ? searchInputs[accessor].toLowerCase() : '';
    const cellValue = booking[accessor].toString().toLowerCase();

    // Modify the condition to check if either first or last name contains the search value
    return accessor === 'firstName' || accessor === 'lastName'
      ? `${booking.firstName} ${booking.lastName}`.toLowerCase().includes(searchValue)
      : cellValue.includes(searchValue);
  })
);


const handleInputChange = (event) => {
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value });
  if (name == "fourwheels" && value >= 1) {
    setTotalCost(value * selectedTourPrice)
  }
};

// Step 3: Add onSubmit handler to gather and handle the form data
const handleSubmit = async (event) => {
  event.preventDefault();

  // Grab values from local storage
  const tourId = JSON.parse(localStorage.getItem('tourId'));
  const bookingDate = JSON.parse(localStorage.getItem('bookingDate'));
  const bookingTime = JSON.parse(localStorage.getItem('bookingTime'));

  // Update the form data with values from local storage
  const updatedFormData = {
    ...formData,
    activityId: tourId,
    date: bookingDate,
    time: bookingTime,
    duration: selectedTourDuration,
    orderNumber: orderNumber,
    price: selectedTourPrice,
    activityTitle: selectedTourTitle
  };

  try {
    const { data } = await createBooking({
      variables: updatedFormData,
    });
    const socket = io('https://cafecolaord.com', { transports: ['polling'] });

    socket.emit('newBooking', data)

        // Clear the form data and reset relevant state variables
    setFormData({
      firstName: '',
      lastName: '',
      fourwheels: '',
      people: '',
      phone: '',
      email: '',
    });
    setSelectedTour(null);
    setSelectedTourDuration(null);
    setTotalCost(null);
    setselectedTourPrice(null);

    handleClose()
    // Add your logic to handle the updated form data, e.g., submit it to the server
    localStorage.removeItem('tourId');
    localStorage.removeItem('bookingDate');
    localStorage.removeItem('bookingTime');
    
  } catch (error) {
    console.error('Error creating booking:', error.message);
  }

};



  const tourOptions = allActivities.map((activity) => (
    <button key={activity.priceId} className={`btn tour-option ${selectedTour === activity.priceId ? 'selected' : ''}`} onClick={() => handleTourSelection(activity.priceId, activity.duration, activity.price, activity.title)} style={{padding: "0px"}}>
      <Card>
        <Card.Body>
          <Card.Title>{activity.duration}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Fourwheelers</Card.Subtitle>
        </Card.Body>
        <Card.Body className='footer-body'>
          <Card.Footer>
            <Badge bg="primary">DOP ${activity.price.toLocaleString()}</Badge>
            {/* <Badge bg="primary">USD ${Math.round(activity.price / 56).toLocaleString()}</Badge> */}
          </Card.Footer>
        </Card.Body>
      </Card>
    </button>
  ));

  const handleRowClick = (booking) => {
    setSelectedBookingDetail(booking)
    handleShowDetail();
  };

  const compareDatesDesc = (a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
  
    return dateB - dateA;
  };

  const sortedBookings = filteredBookings.sort(compareDatesDesc);

  
  const now = 3;
  if(employee.role === 'owner') {
    return (
      <div className={`bookingData ${isDarkMode ? 'bg-dark' : 'bg-light'}`}>
        <h1 style={{fontWeight: "700", color: "#0d6efd"}}>TOURS</h1>
        <Button href="admin/hotel" variant="secondary" size="lg">Hotel</Button>
        <div className='table-container'>
          <Button onClick={toggleMode} id='toggleModeButton' border='true' variant={isDarkMode ? 'outline-light' : 'outline-dark'}>
            {isDarkMode ? <MdLightMode/> : <MdDarkMode/>}
          </Button>


          <div className='stats'>
            <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
              <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Reservas Totales</Card.Subtitle>
                  <Card.Title>{allBookings.length}</Card.Title>
                  {/* <ProgressBar animated striped min={0} max={allBookings.length} now={now} label={`${Math.round((now / allBookings.length) * 100)}%`} /> */}
                </Card.Body>
              </Card>

              <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
                <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Fourwheels Reservados</Card.Subtitle>
                  <Card.Title>{totalFourWheelers}</Card.Title>
                </Card.Body>
              </Card>

              <Card bg={isDarkMode ? 'dark' : 'light'} text={isDarkMode ? 'light' : 'dark'} className="text-start">
                <Card.Body>
                  <Card.Subtitle className={`${isDarkMode ? 'text-light' : 'text-dark'} mb-2`}>Ingresos Totales</Card.Subtitle>
                  <Card.Title>${totalRevenue}</Card.Title>
              </Card.Body>
            </Card>
          </div>

          <div className='action-buttons'>
            <Button variant="primary" className='new' onClick={handleShow}>+ Nueva Reserva</Button>
          </div>

          <Table striped responsive bordered hover variant={isDarkMode ? 'dark' : 'light'} className='bookingTable'>
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column.Header}>
                    {column.Header}
                    <br />
                    <input
                      type="text"
                      className="search-input"
                      value={searchInputs[column.accessor] || ''}
                      onChange={(e) => handleSearchInputChange(column.accessor, e.target.value)}
                      placeholder="Buscar..."
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {sortedBookings.map((booking, index) => (
                <tr key={index} onClick={() => handleRowClick(booking)} style={{ cursor: 'pointer' }}>
                  {columns.map((column, columnIndex) => (
                    <td key={`${index}-${columnIndex}`}>
                      {column.accessor === 'firstName' ? `${booking.firstName} ${booking.lastName}` : booking[column.accessor]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>


        <Modal show={show} onHide={handleClose} scrollable='true' size='xl' centered='true' backdrop='true'>
          <Modal.Header closeButton>
            <Modal.Title>Nueva Reserva</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          Selecciona Un Tour:
            <div className='tour-options'>
              {tourOptions}
            </div>
          {selectedTour ? (
            <div>
              Selecciona Cuando:

              <AdminCalendar key={adminCalendarKey} id={selectedTour ? selectedTour : 'none'} activityDuration={selectedTourDuration} />
            </div>
          ) : (
            <br/>
          )}




            <Form onSubmit={handleSubmit}>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridState">
                  <Form.Label>Nombre</Form.Label>
                  <Form.Control placeholder='Nombre'name='firstName' value={formData.firstName} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridZip">
                  <Form.Label>Apellido</Form.Label>
                  <Form.Control placeholder='Apellido' name='lastName' value={formData.lastName} onChange={handleInputChange}/>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridAddress1">
                  <Form.Label>Fourwheelers Cantidad</Form.Label>
                  <Form.Control type='number' placeholder="Fourwheelers Cantidad" name='fourwheels' value={formData.fourwheels} onChange={handleInputChange}/>
                </Form.Group>

                <Form.Group as={Col} controlId="formGridAddress2">
                  <Form.Label>Personas Cantidad</Form.Label>
                  <Form.Control type='number' placeholder="Personas Cantidad" name='people' value={formData.people} onChange={handleInputChange}/>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} controlId="formGridPassword">
                  <Form.Label>Numero Telefonico</Form.Label>
                  <Form.Control placeholder="Numero Telefonico" name='phone' value={formData.phone} onChange={handleInputChange}/>
                </Form.Group>
                <Form.Group as={Col} controlId="formGridEmail">
                  <Form.Label>Correo Electronico</Form.Label>
                  <Form.Control type="email" placeholder="Correo Electronico" name='email' value={formData.email} onChange={handleInputChange}/>
                </Form.Group>
              </Row>

              <h4>Total ${(totalCost * 1).toLocaleString("en-US")} DOP</h4>
              <Button variant="primary" type="submit">
                Reservar
              </Button>

            </Form>
          </Modal.Body>
        </Modal>

        <Modal show={showDetail} onHide={handleCloseDetail} scrollable='true' size='xl' centered='true' backdrop='true'>
          <Modal.Header closeButton>
            <Modal.Title>{selectedBookingDetail ? `Detalles de la Reserva` : 'Nueva Reserva'}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedBookingDetail ? (
              <div>
                <p>Cliente: {`${selectedBookingDetail.firstName} ${selectedBookingDetail.lastName}`}</p>
                <p>Fecha: {selectedBookingDetail.date}</p>
                <p>Hora: {selectedBookingDetail.time}</p>
                <p>Duración: {selectedBookingDetail.duration}</p>
                <p>Fourwheels: {selectedBookingDetail.fourwheels}</p>
                <p>Personas: {selectedBookingDetail.people}</p>
                <p>Número de teléfono: {selectedBookingDetail.phone}</p>
                <p>Correo Electronico: {selectedBookingDetail.email}</p>
                <p>Hora: {selectedBookingDetail.time}</p>
                <p>Status: {selectedBookingDetail.paymentStatus}</p>
                <p>Numero de Reserva: {selectedBookingDetail.orderNumber}</p>
              </div>
            ) : (
              <div>
                Selecciona Un Tour:
              </div>
            )}
          </Modal.Body>
        </Modal>
      </div>
    )
  } else {
      return (
      <div className="mainContainer">
        <p>ALL USERS PAGE</p>
        {!userParam && (
          <div
            className="divider"
            style={{ border: '1px dotted #1a1a1a', display: 'none'}}
          >
          </div>
        )}
      </div>
  );
  }



};

export default Dashboard;