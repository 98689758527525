import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_ROOM_BY_PRICE_ID } from "../utils/queries";
import { ADD_ROOM_RESERVATION_TEMP } from '../utils/mutations';
import { BsArrowLeft } from "react-icons/bs";

import ReservationSummary from "./ReservationSummary";
import Azul from "../components/Azul";
import { generateOrderNumber } from "../utils/orderNumber";

export default function ReservationForm({ title }) {

  const[createRoomReservationTemp, { roomReservationTempError, newRoomReservationTempData }] = useMutation(ADD_ROOM_RESERVATION_TEMP);

  const [roomReservationData, setRoomReservationData] = useState({
    roomId: "",
    roomTitle: "",
    roomNumber: "",
    price: "",
    checkInDate: "",
    checkOutDate: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    breakfast: "false",
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isBreakfastChecked, setIsBreakfastChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submit, setSubmit] = useState(false);

  const createOrderNumber = generateOrderNumber();
  const [orderNumber, setOrderNumber] = useState('');
  const [totalNights, setTotalNights] = useState('');

  useEffect(() => {
    const areFieldsFilled =
      roomReservationData.firstName &&
      roomReservationData.lastName &&
      roomReservationData.email &&
      roomReservationData.phone &&
      isCheckboxChecked;

    setIsFormValid(areFieldsFilled);
  }, [roomReservationData, isCheckboxChecked, isBreakfastChecked]);

  // Load roomReservationData from local storage when the component mounts
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("roomReservationData"));
    if (storedData) {
      setRoomReservationData(storedData);
    }
  }, []);

  // Save roomReservationData to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("roomReservationData", JSON.stringify(roomReservationData));
  }, [roomReservationData]);

  const activityId = JSON.parse(localStorage.getItem("roomId"));
  const priceId = activityId;

  if (!activityId) {
    const redirectTo = `${window.location.origin}/hotel`;

    // Perform the redirection
    window.location.href = redirectTo;

    // You might also return a placeholder here if you want to render something while redirecting
    return <div>Redirecting...</div>;
  }

  const { loading, error, data } = useQuery(QUERY_ROOM_BY_PRICE_ID, {
    variables: { priceId },
    skip: !activityId,
  });

  useEffect(() => {
    if (error) {
      const redirectTo = `${window.location.origin}/hotel`;

      // Perform the redirection
      window.location.href = redirectTo;
    }
  }, [error]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const activity = data.roomByPriceId;
  
  const reservationDates = JSON.parse(localStorage.getItem("reservationDates"))

  if (!reservationDates) {
    const redirectTo = `${window.location.origin}/hotel`;

    // Perform the redirection
    window.location.href = redirectTo;
  }

  const handleBookingFormSubmit = async (e) => {
    e.preventDefault();

    // Update the roomReservationData object with the form data
    const updatedBookingData = {
      ...roomReservationData,
      roomId: activity.priceId,
      title: activity.title,
      orderNumber: createOrderNumber,
      price: activity.price,
      roomTitle: activity.title,
      checkInDate: reservationDates.checkIn,
      checkOutDate: reservationDates.checkOut,
      breakfast: isBreakfastChecked ? "true" : "false" 
    };

    try {
      const { data } = await createRoomReservationTemp({
        variables: updatedBookingData,
      });

      const checkIn = new Date(reservationDates.checkIn);
      const checkOut = new Date(reservationDates.checkOut);
      const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
      const nights = Math.round(Math.abs((checkIn - checkOut) / oneDay));
      setTotalNights(nights)
      setOrderNumber(data.createReservationTemp.orderNumber);
      setSubmit(true);
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRoomReservationData((prevData) => ({ ...prevData, [name]: value }));
  };

  return (
    <div className="booking-section">
      <div className="booking-container">
      <p className="booking-steps"><div className="backNav"><a href={`/hotel/${priceId}`}><BsArrowLeft/>Regresar</a></div><span>Paso 1 de 2</span></p>
        <h2>Formulario de Reserva</h2>
        <div className="info-container">
          <form onSubmit={handleBookingFormSubmit}>
            <div className="input-container">
              <label htmlFor="firstName">Nombre <span>*</span></label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={roomReservationData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="lastName">Apellido <span>*</span></label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={roomReservationData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="email">Correo Electrónico <span>*</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={roomReservationData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="phone">Teléfono <span>*</span></label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={roomReservationData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label className="terms-check">
                <input
                  type="checkbox"
                  checked={isBreakfastChecked}
                  onChange={() => setIsBreakfastChecked(!isBreakfastChecked)}
                />
                Incluir Desayuno (+ RD $1,000/noche)
              </label>
            </div>
            <div className="input-container">
              <label className="terms-check">
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                Aceptar <a href="/terms" target="_blank">Términos y Condiciones</a> <span>*</span>
              </label>
            </div>
            <div className="input-container">
              <h4>Política de Cancelación</h4>
              <p>No es posible cancelar o realizar cambios después de realizar la reserva</p>
            </div>
            <button className={`submit-form ${!isFormValid ? 'disabled' : ''}`} type="submit" disabled={!isFormValid}>Siguiente</button>
          </form>
          <ReservationSummary title={activity.title} cover={activity.images[0]} checkIn={reservationDates.checkIn} checkOut={reservationDates.checkOut} breakfast={isBreakfastChecked ? "true" : "false"} price={activity.price} ac={activity.ac}/>
          <Azul type={"hotel"} submit={submit} orderNumber={orderNumber} totalPrice={Math.round((parseInt(activity.price) + (isBreakfastChecked ? 1000 : 0)) * totalNights * 1.1)}/>
        </div>
      </div>
    </div>
  );
}
