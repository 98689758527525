import React from 'react';

const Tour2 = () => {
  return (
    <div>
      <div className="tour-details-container">

        <div className="tour-details-overview">
          <h2>Descripción general</h2>
          <p>
            Embárcate en una inolvidable aventura off-road de 1 hora a través de los impresionantes paisajes que rodean Jarabacoa. Este emocionante tour te lleva fuera del camino trillado, brindándote vistas impresionantes, senderos naturales, experiencias con barro y agua. Atraviesa ríos y navega por montañas, mejorando tu viaje con paisajes asombrosos en cada giro.
          </p>
        </div>

        <div className="tour-details-highlights">
          <h2>Aspectos destacados del tour</h2>
          <ul>
            <li>Senderos off-road escénicos: Sumérgete en la belleza de la naturaleza mientras navegas por senderos off-road.</li>
            <li>Vistas impresionantes: Disfruta de vistas panorámicas de la ciudad, las montañas y los paisajes circundantes desde puntos elevados.</li>
            <li>Aventura con barro y agua: Experimenta la emoción de navegar a través de obstáculos de barro y agua, agregando un elemento adicional de emoción a tu viaje.</li>
          </ul>
        </div>

        <div className="tour-details-details">
          <h2>Detalles</h2>
          <p>Duración: 1 hora de aventura y exploración sin parar.</p>
          <p>Inclusiones:</p>
          <ul>
            <li>Recorrido guiado con instructores experimentados off-road.</li>
            <li>Uso de vehículo off-road equipado para senderos de barro, agua y montaña.</li>
            <li>Equipo de seguridad y briefing.</li>
          </ul>
        </div>

        <div className="tour-details-important">
          <h2>Información importante</h2>
          <p>Punto de partida: 49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, República Dominicana</p>
          <p>Requisitos de edad: Participantes de todas las edades son bienvenidos. Para los niños, se requiere consentimiento parental.</p>
          <p>Medidas de seguridad: Se proporcionan cascos y equipo de seguridad. Sigue las instrucciones del guía para una experiencia segura y placentera.</p>
        </div>
      </div>
    </div>
  );
};

export default Tour2;
