import React, {lazy, useState, useEffect} from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';

// Pages EN
import Home from "./pages/Home";
import Reservations from './pages/Reservations';
import Hotel from './pages/Hotel';
import Policies from './pages/Policies';
import Terms from './pages/Terms';

import HotelCompletion from './pages/HotelCompletion';
import Completion from './pages/Completion';
import Decline from './pages/Decline';
import Cancellation from './pages/Cancel';
// Components EN
import BookingPage from './components/BookingPage';
import ReservationPage from './components/ReservationPage';
import CheckoutForm from './components/CheckoutForm';
import Payment from './components/Payment';
import ActivityDetail from './pages/ActivityDetail';
import RoomDetail from './pages/RoomDetail';


// Pages ES
import HomeES from "./pagesES/Home";
import ReservationsES from './pagesES/Reservations';
import HotelES from './pagesES/Hotel';
import PoliciesES from './pagesES/Policies';
import TermsES from './pagesES/Terms';

import HotelCompletionES from './pagesES/HotelCompletion';
import CompletionES from './pagesES/Completion';
import DeclineES from './pagesES/Decline';
import CancellationES from './pagesES/Cancel';

// Components ES
import BookingPageES from './componentsES/BookingPage';
import ReservationPageES from './componentsES/ReservationPage'
import CheckoutFormES from './componentsES/CheckoutForm';
import PaymentES from './componentsES/Payment';
import ActivityDetailES from './pagesES/ActivityDetail';
import RoomDetailES from './pagesES/RoomDetail';
// const Home = React.lazy(() => import('./pages/Home'));

import Navbar from './components/Navbar';
// Admin
import Dashboard from './pagesMain/Dashboard';
import DashboardHotel from './pagesMain/DashboardHotel';
import Login from './pagesMain/Login';


// Construct our main GraphQL API endpoint
const httpLink = createHttpLink({
  uri: '/graphql',
});

// Construct request middleware that will attach the JWT token to every request as an `authorization` header
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('id_token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const client = new ApolloClient({
  // Set up our client to execute the `authLink` middleware prior to making the request to our GraphQL API
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

function App() {

    return (
      <ApolloProvider client={client}>
        <Router>
          <MainRoutes/>
        </Router>
      </ApolloProvider>
    );
}


function MainRoutes() {
  const location = useLocation();

  const [selectedLanguage, setSelectedLanguage] = useState("es");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  
  useEffect(() => {
    // Load the selected language from local storage
    const storedLanguage = localStorage.getItem('selectedLanguage');
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  useEffect(() => {
    const canonicalUrl = document.getElementById('canonicalUrl');
    if (canonicalUrl) {
      canonicalUrl.href = window.location.origin + location.pathname;
    }
  }, [location.pathname]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
    setDropdownVisible(false);
    // Save the selected language to local storage
    localStorage.setItem('selectedLanguage', language);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <div>
      <div className="content-container">
        {selectedLanguage === "en" ? 
          <Routes>
            <Route path="/" element={<Home selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}  />} />
            <Route path="/tours" element={<Reservations selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />
            <Route path="/tours/:id" element={<ActivityDetail />} />
            <Route path='/hotel' element={<Hotel selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />}/>
            <Route path='/hotel/:id' element={<RoomDetail/>}/>
            <Route path='/hotel/reservation/:id' element={<ReservationPage/>}/>
            <Route path="/booking/:id" element={<BookingPage />} />
            {/* <Route path="/payment" element={<Payment />} /> */}

            <Route path="/tour/completion" element={<Completion />} />
            <Route path="/tour/decline" element={<Decline />} />
            <Route path="/tour/cancel" element={<Cancellation />} />

            <Route path="/hotel/completion" element={<HotelCompletion />} />
            <Route path="/hotel/decline" element={<Decline />} />
            <Route path="/hotel/cancel" element={<Cancellation />} />

            <Route path="/policies" element={<Policies selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />
            <Route path="/terms" element={<Terms selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />


            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/hotel" element={<DashboardHotel />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/hotel/login" element={<Login />} />
          </Routes>
        : 
          <Routes>
            <Route path="/" element={<HomeES selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />
            <Route path="/tours" element={<ReservationsES selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />
            <Route path="/tours/:id" element={<ActivityDetailES/>} />
            <Route path='/hotel' element={<HotelES selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />}/>
            <Route path='/hotel/:id' element={<RoomDetailES/>}/>
            <Route path='/hotel/reservation/:id' element={<ReservationPageES/>}/>
            <Route path="/booking/:id" element={<BookingPageES />} />
            {/* <Route path="/payment" element={<PaymentES />} /> */}

            <Route path="/tour/completion" element={<CompletionES />} />
            <Route path="/tour/decline" element={<DeclineES />} />
            <Route path="/tour/cancel" element={<CancellationES />} />

            <Route path="/hotel/completion" element={<HotelCompletionES />} />
            <Route path="/hotel/decline" element={<DeclineES />} />
            <Route path="/hotel/cancel" element={<CancellationES />} />

            <Route path="/policies" element={<PoliciesES selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />
            <Route path="/terms" element={<TermsES selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible} />} />


            <Route path="/admin" element={<Dashboard />} />
            <Route path="/admin/hotel" element={<DashboardHotel />} />
            <Route path="/admin/login" element={<Login />} />
            <Route path="/admin/hotel/login" element={<Login />} />
          </Routes>
        }
      </div>
    </div>
  );
}

export default App;