import { gql } from '@apollo/client';

export const LOGIN_EMPLOYEE = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      token
      employee {
        _id
        username
      }
    }
  }
`;

export const ADD_EMPLOYEE = gql`
  mutation addEmployee($username: String!, $firstName: String!, $lastName: String!, $password: String!, $role: String!) {
    addEmployee(username: $username, firstName: $firstName, lastName: $lastName, password: $password, role: $role) {
      token
      employee {
        _id
        username
      }
    }
  }
`;

export const ADD_BOOKING = gql`
  mutation createBooking($orderNumber: String! $paymentStatus: String $date: String! $time: String! $firstName: String! $lastName: String! $email: String! $phone: String $people: String $fourwheels: String $duration: String $price: String $activityTitle: String $activityId: String!) {
    createBooking(orderNumber: $orderNumber paymentStatus: $paymentStatus date: $date time: $time firstName: $firstName lastName: $lastName email: $email phone: $phone people: $people fourwheels: $fourwheels duration: $duration price: $price activityTitle: $activityTitle activityId: $activityId ) {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;

export const ADD_BOOKING_TEMP = gql`
  mutation createBookingTemp($orderNumber: String! $paymentStatus: String $date: String! $time: String! $firstName: String! $lastName: String! $email: String! $phone: String $people: String $fourwheels: String $duration: String $price: String $activityTitle: String $activityId: String!) {
    createBookingTemp(orderNumber: $orderNumber paymentStatus: $paymentStatus date: $date time: $time firstName: $firstName lastName: $lastName email: $email phone: $phone people: $people fourwheels: $fourwheels duration: $duration price: $price activityTitle: $activityTitle activityId: $activityId ) {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;


export const UPDATE_BOOKING_PAYMENT_STATUS = gql`
  mutation updateBookingPaymentStatus($orderNumber: String!, $paymentStatus: String!) {
    updateBookingPaymentStatus(orderNumber: $orderNumber, paymentStatus: $paymentStatus) {
      _id
      orderNumber
      paymentStatus
      date
      time
      firstName
      lastName
      email
      phone
      people
      fourwheels
      duration
      price
      activityTitle
      activityId
    }
  }
`;

export const ADD_ROOM_RESERVATION = gql`
mutation createReservation($roomId: String!, $roomTitle: String, $orderNumber: String, $roomNumber: String, $paymentStatus: String $price: String! $breakfast: String $checkInDate: String! $checkOutDate: String! $firstName: String! $lastName: String! $email: String! $phone: String) {
  createReservation(roomId: $roomId roomTitle: $roomTitle orderNumber: $orderNumber roomNumber: $roomNumber paymentStatus: $paymentStatus price: $price breakfast: $breakfast checkInDate: $checkInDate checkOutDate: $checkOutDate firstName: $firstName lastName: $lastName email: $email phone: $phone) {
    _id
    roomId
    roomTitle
    orderNumber
    roomNumber
    paymentStatus
    price
    breakfast
    checkInDate
    checkOutDate
    firstName
    lastName
    email
    phone
  }
}
`;

export const ADD_ROOM_RESERVATION_TEMP = gql`
mutation createReservationTemp($roomId: String!, $roomTitle: String, $orderNumber: String, $roomNumber: String, $paymentStatus: String $price: String! $breakfast: String $checkInDate: String! $checkOutDate: String! $firstName: String! $lastName: String! $email: String! $phone: String) {
  createReservationTemp(roomId: $roomId roomTitle: $roomTitle orderNumber: $orderNumber roomNumber: $roomNumber paymentStatus: $paymentStatus price: $price breakfast: $breakfast checkInDate: $checkInDate checkOutDate: $checkOutDate firstName: $firstName lastName: $lastName email: $email phone: $phone) {
    _id
    roomId
    roomTitle
    orderNumber
    roomNumber
    paymentStatus
    price
    breakfast
    checkInDate
    checkOutDate
    firstName
    lastName
    email
    phone
  }
}
`;