import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { QUERY_ROOM_BY_PRICE_ID } from "../utils/queries";

import DateRangePicker from "../components/DateRangePicker";

import Carousel from "../components/Carousel"; 

import { BsImages, BsXLg, BsClock, BsCalendar2Date, BsArrowLeft, BsSnow } from "react-icons/bs";
import { PiFan, PiPawPrintLight, PiQuestionLight  } from "react-icons/pi";
import { FaSwimmingPool, FaParking, FaWifi   } from "react-icons/fa";
import { RxExit, RxEnter, RxInfoCircled } from "react-icons/rx";
import { MdOutlineNoFood } from "react-icons/md";

import CafeColao from "../images/cafe-colao-logo.png"

const RoomDetailsPage = () => {
  const [viewAllVisible, setViewAllVisible] = useState(false);
  const { id } = useParams();

  const priceId = id;
  
  useEffect(() => {
    // localStorage.clear()
    localStorage.removeItem('roomId');
    localStorage.removeItem('roomReservationData');

    localStorage.setItem("roomId", JSON.stringify(priceId));
  }, []);

    // Add a useEffect to apply/remove overflow: hidden to the body
    useEffect(() => {
      if (viewAllVisible) {
        // When viewAllVisible is true, apply overflow: hidden to the body
        document.body.style.overflow = "hidden";
      } else {
        // When viewAllVisible is false, remove overflow: hidden from the body
        document.body.style.overflow = "auto";
      }
  
      // Cleanup function to remove the style when the component unmounts
      return () => {
        document.body.style.overflow = "auto";
      };
    }, [viewAllVisible]);

  const { loading, error, data } = useQuery(QUERY_ROOM_BY_PRICE_ID, {
    variables: { priceId },
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const room = data.roomByPriceId;

  const toggleViewAll = () => {
    setViewAllVisible(!viewAllVisible);
  };
  
  return (
    <div className="roomDetail activityDetail">
      <div className="room-content activity-content">
        <div className="backNav">
          <a href="/hotel"><BsArrowLeft/>All Rooms</a>
        </div>
        <div style={{width: "100%", textAlign: "center"}}>
          <a href="/"><img src={CafeColao} alt="Cafe Colao Jarabacoa" style={{ width: "90px" }}/></a>
        </div>
        <section className="photo-array">
          <div style={{ backgroundImage: `url(${room.images[0]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[1]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[2]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[3]})`}}></div>
          <div style={{ backgroundImage: `url(${room.images[4]})`}} onClick={toggleViewAll} ><div className="view-all"><BsImages/>View All</div></div>
        </section>
        {viewAllVisible && (
          <>
            <Carousel images={room.images} />
            <button onClick={toggleViewAll} className="close-full"><BsXLg/></button>
          </>
        )}
        <div className="mobile-carousel">
          <Carousel images={room.images} />
        </div>
        <h1>{room.title}</h1>
        <div className="desc-cards">

          {/* Duration Card */}
          {room.ac === 'true' ? (
            <div className="desc-card">
              <BsSnow/>
              <div className="text">
                <h3>AC</h3>
              </div>
            </div>
            ) : (
              <div className="desc-card">
              <PiFan/>
              <div className="text">
                <h3>Fan</h3>
              </div>
            </div>
            )}
            <div className="desc-card">
              <FaSwimmingPool/>
              <div className="text">
                <h3>Pool</h3>
              </div>
            </div>
            <div className="desc-card">
              <FaParking/>
              <div className="text">
                <h3>Free Parking</h3>
              </div>
            </div>
            <div className="desc-card">
              <FaWifi/>
              <div className="text">
                <h3>Free Wifi</h3>
              </div>
            </div>
        </div>
        {/* <div className='DateRangePickerContainer'>
            <DateRangePicker roomId={priceId} />
        </div> */}
        <div className="room-detail-container">
          {/* <h2 className="room-heading">Welcome to our serene retreat in Jarabacoa, Dominican Republic</h2> */}
          <br/>

          <div className="room-section">
            <h2 className="room-subheading">Your stay at Cafe Colao Hotel</h2>
            <p className="room-paragraph">

              Immerse yourself in the local charm with a unique experience inspired
              by the cultural essence of Jarabacoa. Surrounded by lush greenery and a
              picturesque garden, this intimate accommodation is designed for two
              people seeking a peaceful escape. Experience the authenticity of the
              Dominican Republic while enjoying the comforts of modern living.
            </p>
          </div>

          {/* Relaxation and Recreation */}
          <div className="room-section">
            <h2 className="room-subheading">Relaxation and Recreation</h2>
            <p className="room-paragraph">
              Our hotel provides quick access to a refreshing pool, a vibrant bar, and
              other recreational facilities. Whether you're basking in the sun by the
              pool or enjoying a cool beverage at the bar, you'll find our establishment
              to be a perfect blend of relaxation and leisure.
            </p>
          </div>

        </div>
        <div className="house-rules">
          <h2 className="room-subheading">Rules & Details</h2>
          <div className="container">
            <div className="info">
              <div className="title"><RxEnter /> Check-In</div>
              <div className="detail"><p>3:00 PM - 7:00 PM</p></div>
            </div>
            <div className="info">
              <div className="title"><RxExit/> Check-Out</div>
              <div className="detail"><p>Until 12:00 PM</p></div>
            </div>
            <div className="info">
              <div className="title"><RxInfoCircled/> Cancellations</div>
              <div className="detail"><p>Cancellations are currently not allowed for reservations made online. Questions? (829) 752-3723 </p></div>
            </div>
            <div className="info">
              <div className="title"><PiPawPrintLight/> Pets</div>
              <div className="detail"><p>Pets are not allowed.</p></div>
            </div>
            <div className="info">
              <div className="title"><MdOutlineNoFood/> Food</div>
              <div className="detail"><p>Food & drinks are not allowed in the rooms. (Only Water)</p></div>
            </div>
            <div className="info">
              <div className="title"><PiQuestionLight/> Questions?</div>
              <div className="detail"><p>For any questions or assistance contact us at <b>(829) 752-3723</b></p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomDetailsPage;