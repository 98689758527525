import React from "react";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer-container">
            <div className="elfsight-app-89fcc64e-869c-4ae2-b415-2617f87f4615"></div>
            <div className="footer-content">
                <div className="footer-section">
                    <h5 className="title">DIRECCIÓN:</h5>
                    <p>49C5+J8M, Calle Hermanas Mirabal, Jarabacoa 41000, República Dominicana</p>
                </div>
                <div className="footer-section">
                    <h5 className="title">CONTÁCTESE CON NOSOTROS:</h5>
                    <p>809-986-0356</p>
                    <p>809-761-0793</p>
                    <p>CafeColaoBarRestaurante@gmail.com</p>
                </div>
                <div className="footer-section">
                    <h5 className="title">HORARIO:</h5>
                    <p>Lunes - Viernes: 10 AM - 11 PM </p>
                    <p>Sábado - Domingo: 9 AM - 11 PM</p>
                </div>
            </div>
            <ul className="footer-social">
                <li>
                    <a className="social-item" href="https://www.facebook.com/profile.php?id=100057655200928" target="_blank"><FaFacebook/></a>
                </li>
                <li>
                    <a className="social-item" href="https://www.instagram.com/cafecolao__/" target="_blank"><FaInstagram/></a>
                </li>
                <li>
                    <a className="social-item" href="https://www.youtube.com/@cafecolao" target="_blank"><FaYoutube/></a>
                </li>
                <li>
                    <a className="social-item" href="https://www.tiktok.com/@adelsocafecolao" target="_blank"><FaTiktok/></a>
                </li>
            </ul>
            <div className="divider"></div>
            <div className="sub-footer">
                <div className="footer-payments">
                    <span>Opciones de Pago</span>
                    <div className="payment-methods">
                        <span><img src="https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/payment-methods%2FVisa-Logo-2005.jpg?alt=media&token=10067fbd-d253-487d-890c-8b6213d33209" alt="Visa Logo" /></span>
                        <span><img src="https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/payment-methods%2Fvisa-secure_blu_2021.png?alt=media&token=bc58d00e-3b70-45c6-a199-65682285a8ef" alt="Visa Secure Logo" /></span>
                        <span><img src="https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/payment-methods%2Fmastercard-logo.jpg?alt=media&token=cac93bfc-1312-4864-a0df-75866b2c6dc4" alt="Mastercard Logo" /></span>
                        <span><img src="https://firebasestorage.googleapis.com/v0/b/cafecolao-b113a.appspot.com/o/payment-methods%2Fmastercardidentitycheck.png?alt=media&token=fe694b27-eb99-46be-96cd-f25ca4674489" alt="Mastercard ID Check Logo" /></span>
                    </div>
                </div>
                <span className="social-item">© Cafe Colao</span>
                <a className="social-item" href="/policies">Politicas</a>
            </div>
            <div className="created">
                <p>Sitio Web desarrollado por <a href="#">Jose Plasencia</a></p>
            </div>
        </div>
    </footer>
  );
};

export default Footer;
