import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useQuery, useMutation } from "@apollo/client";
import { QUERY_ACTIVITY_BY_PRICE_ID } from "../utils/queries";
import { ADD_BOOKING_TEMP } from "../utils/mutations";
import { BsArrowLeft } from "react-icons/bs";

import BookingSummary from "./BookingSummary";
import Azul from "./Azul";
import { generateOrderNumber } from "../utils/orderNumber";

export default function BookingForm({ title }) {
  const [createBookingTemp, { bookingTempError, newBookingTempData }] =
    useMutation(ADD_BOOKING_TEMP);

  const [bookingData, setBookingData] = useState({
    date: "",
    time: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    people: "",
    fourwheels: 1,
    duration: "",
    activityId: "",
  });

  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [submit, setSubmit] = useState(false);

  const createOrderNumber = generateOrderNumber();
  const [orderNumber, setOrderNumber] = useState("");

  useEffect(() => {
    const areFieldsFilled =
      bookingData.firstName &&
      bookingData.lastName &&
      bookingData.email &&
      bookingData.phone &&
      bookingData.people &&
      isCheckboxChecked;

    setIsFormValid(areFieldsFilled);
  }, [bookingData, isCheckboxChecked]);

  // Load bookingData from local storage when the component mounts
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("bookingData"));
    if (storedData) {
      setBookingData(storedData);
    }
  }, []);

  // Save bookingData to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("bookingData", JSON.stringify(bookingData));
  }, [bookingData]);

  const activityId = JSON.parse(localStorage.getItem("activityId"));
  const priceId = activityId;

  if (!activityId) {
    const redirectTo = `${window.location.origin}/tours`;

    // Perform the redirection
    window.location.href = redirectTo;

    // You might also return a placeholder here if you want to render something while redirecting
    return <div>Redirecting...</div>;
  }

  const maxFourwheelers = JSON.parse(localStorage.getItem("maxFourwheelers"));

  const { loading, error, data } = useQuery(QUERY_ACTIVITY_BY_PRICE_ID, {
    variables: { priceId },
    skip: !activityId,
  });

  useEffect(() => {
    if (error) {
      const redirectTo = `${window.location.origin}/tours`;

      // Perform the redirection
      window.location.href = redirectTo;
    }
  }, [error]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!data || !data.activityByPriceId) {
    // Handle the case where data is not available
    return <div>No data available</div>;
  }

  const activity = data.activityByPriceId;

  const bookingDate = JSON.parse(localStorage.getItem("bookingDate"));
  const bookingTime = JSON.parse(localStorage.getItem("bookingTime"));
  const handleBookingFormSubmit = async (e) => {
    e.preventDefault();

    // Update the bookingData object with the form data
    const updatedBookingData = {
      ...bookingData,
      date: bookingDate,
      time: bookingTime,
      duration: activity.duration,
      activityId: activityId,
      title: activity.title,
      fourwheels: bookingData.fourwheels.toString(),
      orderNumber: createOrderNumber,
      price: activity.price,
      activityTitle: activity.title,
    };

    try {
      const { data } = await createBookingTemp({
        variables: updatedBookingData,
      });

      setOrderNumber(data.createBookingTemp.orderNumber);
      setSubmit(true);
      localStorage.clear();
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setBookingData((prevData) => ({ ...prevData, [name]: value }));
  };

  const incrementFourwheels = () => {
    // Increment the fourwheels count, but ensure it doesn't exceed the maximum (7)
    setBookingData((prevData) => ({
      ...prevData,
      fourwheels: Math.min(prevData.fourwheels + 1, maxFourwheelers),
    }));
  };

  const decrementFourwheels = () => {
    // Decrement the fourwheels count, but ensure it doesn't go below the minimum (1)
    setBookingData((prevData) => ({
      ...prevData,
      fourwheels: Math.max(prevData.fourwheels - 1, 1),
    }));
  };

  return (
    <div className="booking-section">
      <div className="booking-container">
        <p className="booking-steps">
          <div className="backNav">
            <a href={`/tours/${priceId}`}>
              <BsArrowLeft />
              Go Back
            </a>
          </div>
          <span>Step 1 of 2</span>
        </p>
        <h2>Booking Form</h2>
        <div className="info-container">
          <form onSubmit={handleBookingFormSubmit}>
            <div className="input-container fourwheels">
              <label>
                Amount of fourwheels <span>*</span>
              </label>
              <div className="fourwheels-counter">
                <button type="button" onClick={decrementFourwheels}>
                  -
                </button>
                <span>{bookingData.fourwheels}</span>
                <button type="button" onClick={incrementFourwheels}>
                  +
                </button>
              </div>
            </div>
            <div className="input-container">
              <label htmlFor="firstName">
                First Name <span>*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={bookingData.firstName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="lastName">
                Last Name <span>*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={bookingData.lastName}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={bookingData.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label htmlFor="phone">
                Phone <span>*</span>
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={bookingData.phone}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-container">
              <label>
                Amount of People <span>*</span>
              </label>
              <input
                type="number"
                id="people"
                name="people"
                value={bookingData.people}
                onChange={handleInputChange}
                max={bookingData.fourwheels * 2}
              />
            </div>
            <div className="input-container">
              <label className="terms-check">
                <input
                  type="checkbox"
                  checked={isCheckboxChecked}
                  onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                />
                Accept{" "}
                <a href="/terms" target="_blank">
                  Terms and Conditions
                </a>{" "}
                <span>*</span>
              </label>
            </div>
            <div className="input-container">
              <h4>Cancellation Policy</h4>
              <p>It's not possible to cancel or make changes after you book</p>
            </div>
            <button
              className={`submit-form ${!isFormValid ? "disabled" : ""}`}
              type="submit"
              disabled={!isFormValid}
            >
              Next
            </button>
          </form>
          <BookingSummary
            title={activity.title}
            cover={activity.images[0]}
            date={bookingDate}
            time={bookingTime}
            duration={activity.duration}
            price={activity.price}
            fourwheels={bookingData.fourwheels}
          />
          <Azul
            type={"tour"}
            submit={submit}
            orderNumber={orderNumber}
            totalPrice={parseInt(
              Math.round(activity.price) * bookingData.fourwheels * 1.1
            )}
          />
        </div>
      </div>
    </div>
  );
}
