import React from 'react';
import Navbar from '../componentsES/Navbar';

const PoliciesES = ({ selectedLanguage, handleLanguageChange, toggleDropdown, dropdownVisible }) => {
  return (
    <div className="policies-container">
      <Navbar selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} toggleDropdown={toggleDropdown} dropdownVisible={dropdownVisible}/>
      <div className="policies-content">
        <h1>Política de Privacidad</h1>

        <p>
          ¡Bienvenido a Café Colao! Tu privacidad es importante para nosotros y nos comprometemos a proteger tu información personal. Esta Política de Privacidad describe cómo recopilamos, usamos y protegemos los datos que compartes con nosotros.
        </p>

        <h2>1. Información que Recopilamos:</h2>
        <p>
        Recopilamos información personal, como tu nombre, detalles de contacto e información de pago, cuando haces una reserva para nuestros servicios. Es importante destacar que NO almacenamos ni compartimos información de tarjetas u otros datos sensibles. Además, podemos recopilar datos no personales relacionados con tus interacciones en nuestro sitio web, incluidas cookies y patrones de uso.
        </p>

        <h2>2. Cómo Usamos tu Información:</h2>
        <p>
          Utilizamos tu información para procesar reservas, mejorar nuestros servicios y personalizar tu experiencia con Café Colao. También podemos utilizar tus datos con fines de marketing, como ofrecerte ofertas especiales o actualizaciones sobre nuestros servicios. Ten la seguridad de que tu información no se venderá ni compartirá con terceros con fines no relacionados.
        </p>

        <h2>3. Seguridad:</h2>

        <p>
          <strong>Sitio Web:</strong>
          <br />
          Tomamos todas las medidas y precauciones razonables para proteger tu información personal y seguimos las mejores prácticas de la industria para asegurar que tu información no sea utilizada de manera inapropiada, alterada, o destruida.
        </p>

        <p>
          <strong>Pagos:</strong>
          <br />
          Los métodos de pago utilizados por Café Colao son servicios de terceros. Estos servicios de terceros (AZUL) cumplen con todos los estándares de seguridad y cifrado para mantener tu información segura. Solo utilizarán la información necesaria para completar el proceso requerido. También recomendamos leer las Políticas de Privacidad de estos proveedores para entender mejor cómo manejan la información suministrada.
        </p>

        <h2>4. Servicios de Terceros:</h2>
        <p>
          Café Colao puede utilizar servicios de terceros para mejorar la funcionalidad de nuestro sitio web y mejorar la experiencia del usuario. Estos servicios pueden tener sus propias políticas de privacidad, y te animamos a revisarlas. Sin embargo, tu información personal no se compartirá con terceros sin tu consentimiento, excepto según lo requiera la ley.
        </p>

        <h2>5. Tus Opciones:</h2>
        <p>
          Tienes el derecho de controlar la información que proporcionas a Café Colao. Puedes actualizar tus preferencias, solicitar acceso a tus datos o darte de baja de las comunicaciones de marketing en cualquier momento. Simplemente contacta a nuestro equipo de soporte al cliente y te ayudaremos rápidamente.
        </p>

        <h2>6. Cookies:</h2>
        <p>
          Nuestro sitio web utiliza cookies para mejorar tu experiencia de navegación. Puedes gestionar tus preferencias de cookies a través de la configuración de tu navegador. Ten en cuenta que desactivar las cookies puede afectar la funcionalidad de ciertas características en nuestro sitio web.
        </p>

        <h2>7. Devoluciones, Reembolsos y Cancelaciones:</h2>
        <p>
          **Devoluciones:**
          Debido a la naturaleza de nuestros servicios, que son reservas para experiencias sensibles al tiempo, lamentamos informarte que las devoluciones no son aplicables. Estamos comprometidos a brindar momentos inolvidables, y una vez que se confirma una reserva, no se puede devolver al comerciante.
        </p>

        <p>
          **Reembolsos:**
          De manera similar, no ofrecemos reembolsos por reservas confirmadas. Esta política está en vigor debido a la planificación detallada y los recursos involucrados en la preparación de la experiencia que has seleccionado. Apreciamos tu comprensión de que nuestro compromiso con ofrecer servicios de calidad requiere una programación y preparación cuidadosas.
        </p>

        <p>
          **Cancelaciones:**
          Café Colao comprende que los planes pueden cambiar. Aunque no se ofrecen reembolsos, podemos ofrecer flexibilidad para reprogramar o proporcionar créditos para experiencias futuras, según las circunstancias. Por favor, ponte en contacto con nuestro equipo de soporte al cliente para obtener ayuda en tales casos.
        </p>

        <h2>8. Instrucciones de Entrega del Servicio:</h2>
        <p>
          Las experiencias únicas que ofrecemos se entregan a través de servicios personalizados en lugar de productos físicos. Así es cómo funciona nuestra entrega de servicios:
        </p>

        <p>
          8.1. Al completar tu reserva, recibirás una confirmación de reserva que contiene detalles esenciales sobre tu recorrido o reserva.
        </p>

        <p>
          8.2. En la fecha y hora programadas para tu reserva, dirígete al lugar especificado mencionado en tu correo electrónico de confirmación.
        </p>

        <p>
          8.3. Presenta la información de la reserva a nuestro personal dedicado, que puede incluir tu número de confirmación, nombre o número de teléfono.
        </p>

        <p>
          8.4. Después del proceso de verificación, nuestro personal te proporcionará instrucciones para los siguientes pasos en tu experiencia.
        </p>

        <p>
          8.5. Cualquier documento o confirmación relevante se te enviará electrónicamente a través de la dirección de correo electrónico asociada con tu reserva.
        </p>

        <p>
          Asegúrate de seguir estos pasos para garantizar una experiencia fluida y agradable con Café Colao. Si tienes alguna pregunta o inquietud con respecto a la entrega del servicio, no dudes en contactarnos para obtener ayuda o respuestas.
        </p>

        <h2>Contacto:</h2>
        <p>
          Si tienes alguna pregunta o inquietud sobre nuestra política de privacidad, por favor, ponte en contacto con nuestro equipo de soporte al cliente.
        </p>

        <p>
          <h4>Información de Contacto:</h4>
          Correo Electrónico de Soporte al Cliente: 
          contacto@colao.com
          <br />
          Número de Teléfono de Soporte al Cliente: 
          809-986-0356 / 
          809-761-0793
        </p>
      </div>
    </div>
  );
};

export default PoliciesES;