import React from "react";

const Hero = ({ scrollToEvents }) => {
  return (
    <section className="hero-section">
        <div className="hero-image parallax">
            <div className="spacer-container">
                <div className="spacer layer1"></div>
            </div>
        <div className="overlay"></div>
        </div>
        <div className="caption">
          <h1 className="title">Explore<br/> Jarabacoa</h1>
          <p className="subtitle">Embark on a Journey of a Lifetime</p>
          <button className="reserve-button" onClick={scrollToEvents} >View Adventures</button>
          <div className="scroll-down"></div>
        </div>
    </section>
  );
};

export default Hero;